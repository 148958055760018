// import React, { useState, useEffect } from "react";
// import axios from "axios";

// const EditProduct = ({ product, categories, companies, onUpdate }) => {
//   const [productData, setProductData] = useState({ ...product });
//   const [selectedCategory, setSelectedCategory] = useState(product.categoryId || "");
//   const [selectedSubcategory, setSelectedSubcategory] = useState(product.subCategoryId || "");
//   const [subcategoriesList, setSubcategoriesList] = useState([]);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [isUploading, setIsUploading] = useState(false);

//   useEffect(()=>{
//     fetchSubCategories(product.categoryId);
//     console.log(product)
//   },[product])

//   useEffect(() => {
//     // Fetch subcategories for the initial category
//     if (selectedCategory) {
//       const subcategoriesForCategory = subcategoriesList.filter(
//         (sub) => sub.categoryId === selectedCategory
//       );
//       setSubcategoriesList(subcategoriesForCategory);
//     }
//   }, [selectedCategory]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setProductData((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleCategoryChange = (e) => {
//     const selectedValue = e.target.value;
//     setSelectedCategory(selectedValue);
//     setSelectedSubcategory("");
//     console.log(e.target.value)
//     fetchSubCategories(e.target.value);
//     setProductData((prev) => ({ ...prev, category: selectedValue, subCategory: "" }));
//   };

//   const handleSubcategoryChange = (e) => {
//     const selectedValue = e.target.value;
//     setSelectedSubcategory(selectedValue);
//     setProductData((prev) => ({ ...prev, subCategory: selectedValue }));
//   };

//   const fetchSubCategories = (categoryId) => {
//     const token = localStorage.getItem('token');
//     axios.get(`https://emkayindia.in/api/v1/subcategory/all?categoryId=${categoryId}`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       }
//     }) // Replace with your subcategory API endpoint
//       .then(response => {
//         console.log(categoryId);
//         console.log(response.data);
//         setSubcategoriesList(response.data)
//       })
//       .catch(error => console.error('Error fetching subcategories:', error));
//     // console.log('Selected Category:', e.target.value); // This is where you can handle the selected category
//   };

//   const handleImageChange = (index, file) => {
//     const updatedImages = [...productData.images];
//     updatedImages[index] = file;
//     setProductData((prev) => ({ ...prev, images: updatedImages }));
//   };

//   const removeImageField = (index) => {
//     const updatedImages = [...productData.images];
//     updatedImages.splice(index, 1);
//     setProductData((prev) => ({ ...prev, images: updatedImages }));
//   };

//   const addImageField = () => {
//     setProductData((prev) => ({ ...prev, images: [...prev.images, null] }));
//   };

//   const toggleDropdown = () => {
//     setIsDropdownOpen((prev) => !prev);
//   };

//   const handleCheckboxChange = (e) => {
//     const { value, checked } = e.target;
//     const updatedCompanies = checked
//       ? [...productData.companyId, value]
//       : productData.companyId.filter((id) => id !== value);
//     setProductData((prev) => ({ ...prev, companyId: updatedCompanies }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const formData = new FormData();
//     for (const key in productData) {
//       if (key === "images") {
//         productData.images.forEach((image, index) => {
//           if (image instanceof File) {
//             formData.append(`images[${index}]`, image);
//           }
//         });
//       } else if (Array.isArray(productData[key])) {
//         productData[key].forEach((value) => formData.append(key, value));
//       } else {
//         formData.append(key, productData[key]);
//       }
//     }

//     try {
//       const response = await axios.patch(
//         `https://emkayindia.in/api/v1/product/update/${product.id}`,
//         formData,
//         { headers: { "Content-Type": "multipart/form-data" } }
//       );
//       alert("Product updated successfully!");
//       if (onUpdate) onUpdate(response.data);
//     } catch (error) {
//       console.error("Error updating product:", error);
//       alert("Failed to update product.");
//     }
//   };


//   return (
//     <div className="edit-product-container">
//       <form className="product-form font-sans text-[#848484] h-[95h]" onSubmit={handleSubmit}>
//         <h2 className="text-3xl font-bold mt-4">Edit Product</h2>

//         {/* Product Details */}
//         <div className="form-section flex flex-col justify-start mt-2 w-[90%]">
//           <h3 className="mb-4 text-xl">Product Details</h3>
//           <div className="form-row w-[100%] flex justify-between text-lg">
//             <div className="w-[30%]">
//               <label className="text-lg">Name:</label>
//               <input
//                 type="text"
//                 name="name"
//                 className="w-[100%]"
//                 value={productData.name}
//                 onChange={handleInputChange}
//                 required
//               />
//             </div>
//             <div className="w-[30%]">
//               <label className="text-lg">Brand:</label>
//               <input
//                 type="text"
//                 name="brand"
//                 className="w-[100%]"
//                 value={productData.brand}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div className="w-[30%]">
//               <label className="text-lg">HSN:</label>
//               <input
//                 type="text"
//                 name="hsn"
//                 className="w-[100%]"
//                 value={productData.hsn}
//                 onChange={handleInputChange}
//                 required
//               />
//             </div>
//           </div>

//           {/* Categories and Subcategories */}
//           <div className="form-row w-[100%] flex justify-between text-lg">
//             <div className="w-[30%] mt-[-0.5rem]">
//               <label className="text-lg">Category:</label>
//               <select
//                 onChange={handleCategoryChange}
//                 value={selectedCategory}
//                 className="w-full px-3 py-2 border border-gray-300 bg-gray-100 text-sm appearance-none"
//               >
//                 <option value="">Select a category</option>
//                 {categories.map((category) => (
//                   <option key={category.id} value={category.id}>
//                     {category.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="w-[30%] mt-[-0.5rem]">
//               <label className="text-lg">Subcategory:</label>
//               <select
//                 onChange={handleSubcategoryChange}
//                 value={selectedSubcategory}
//                 className="w-full px-3 py-2 border border-gray-300 bg-gray-100 text-sm appearance-none"
//                 disabled={!subcategoriesList.length}
//               >
//                 <option value="">Select a subcategory</option>
//                 {subcategoriesList.map((subcategory) => (
//                   <option key={subcategory.id} value={subcategory.id}>
//                     {subcategory.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </div>
//         </div>

//         {/* Images Section */}
//         {/* <div className="form-section mt-4 w-[90%]">
//           <h3 className="font-sans text-xl">Product Images</h3>
//           {productData.image.map((image, index) => (
//             <div key={index} className="form-row mt-2 w-[37%]">
//               <label className="text-lg">
//                 Image {index + 1}:
//                 <input
//                   type="file"
//                   onChange={(e) => handleImageChange(index, e.target.files[0])}
//                   className="w-[100%]"
//                 />
//               </label>
//               <button
//                 type="button"
//                 style={{
//                   background: "#C6C5B9",
//                   color: "#848484",
//                   border: "none",
//                   padding: "8px 10px",
//                   cursor: "pointer",
//                 }}
//                 onClick={() => removeImageField(index)}
//               >
//                 Remove
//               </button>
//             </div>
//           ))}
//           <button
//             type="button"
//             onClick={addImageField}
//             className="img-btn bg-[#C6C5B9] ml-[90%]"
//           >
//             Add Image
//           </button>
//         </div> */}

//         {/* Selected Companies */}
//         <div className="form-section mt-4 w-[90%]">
//           <h3 className="font-sans text-xl">Companies</h3>
//           <div className="company-selection mb-4 mt-4 flex justify-between">
//             <button
//               type="button"
//               className="dropdown-button bg-[#C6C5B9] text-lg"
//               onClick={toggleDropdown}
//             >
//               Select Companies
//             </button>
//             {isDropdownOpen && (
//               <div className="dropdown-content">
//                 {companies.map((company) => (
//                   <div key={company.id} className="flex">
//                     <input
//                       type="checkbox"
//                       value={company.id}
//                       checked={productData.companyId.includes(company.id)}
//                       onChange={handleCheckboxChange}
//                     />
//                     <p>{company.companyName}</p>
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//         </div>

//         <button
//           type="submit"
//           className="bg-[#C6C5B9] text-[#848484] font-semibold"
//         >
//           Update Product
//         </button>
//       </form>
//     </div>
//   );
// };

// export default EditProduct;


// import React, { useState, useEffect } from "react";
// import axios from "axios";

// const EditProduct = ({ product, categories, companies, onUpdate }) => {
//   const [productData, setProductData] = useState({ ...product });
//   const [selectedCategory, setSelectedCategory] = useState(product.categoryId || "");
//   const [selectedSubcategory, setSelectedSubcategory] = useState(product.subCategoryId || "");
//   const [subcategoriesList, setSubcategoriesList] = useState([]);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [isUploading, setIsUploading] = useState(false);

//   useEffect(() => {
//     // Fetch initial subcategories
//     if (product.categoryId) {
//       fetchSubCategories(product.categoryId);
//     }
//     console.log("Product data:", product);
//   }, [product]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setProductData((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleCategoryChange = (e) => {
//     const selectedValue = e.target.value;
//     setSelectedCategory(selectedValue);
//     setSelectedSubcategory("");
//     fetchSubCategories(selectedValue);
//     setProductData((prev) => ({ ...prev, categoryId: selectedValue, subCategoryId: "" }));
//   };

//   const handleSubcategoryChange = (e) => {
//     const selectedValue = e.target.value;
//     setSelectedSubcategory(selectedValue);
//     setProductData((prev) => ({ ...prev, subCategoryId: selectedValue }));
//   };

//   const fetchSubCategories = (categoryId) => {
//     const token = localStorage.getItem("token");
//     axios
//       .get(`https://emkayindia.in/api/v1/subcategory/all?categoryId=${categoryId}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((response) => {
//         console.log("Subcategories:", response.data);
//         setSubcategoriesList(response.data);
//       })
//       .catch((error) => console.error("Error fetching subcategories:", error));
//   };

//   const handleImageChange = (index, file) => {
//     const updatedImages = [...productData.images];
//     updatedImages[index] = file;
//     setProductData((prev) => ({ ...prev, images: updatedImages }));
//   };

//   const addImageField = () => {
//     setProductData((prev) => ({ ...prev, images: [...prev.images, null] }));
//   };

//   const removeImageField = (index) => {
//     const updatedImages = productData.images.filter((_, i) => i !== index);
//     setProductData((prev) => ({ ...prev, images: updatedImages }));
//   };

//   const toggleDropdown = () => {
//     setIsDropdownOpen((prev) => !prev);
//   };

//   const handleCheckboxChange = (e) => {
//     const { value, checked } = e.target;
//     const updatedCompanies = checked
//       ? [...productData.companyId, value]
//       : productData.companyId.filter((id) => id !== value);
//     setProductData((prev) => ({ ...prev, companyId: updatedCompanies }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     setIsUploading(true);

//     const formData = new FormData();
//     for (const key in productData) {
//       if (key === "images") {
//         productData.images.forEach((image, index) => {
//           if (image instanceof File) {
//             formData.append(`images[${index}]`, image);
//           }
//         });
//       } else if (Array.isArray(productData[key])) {
//         productData[key].forEach((value) => formData.append(key, value));
//       } else {
//         formData.append(key, productData[key]);
//       }
//     }

//     try {
//       const response = await axios.patch(
//         `https://emkayindia.in/api/v1/product/update/${product.id}`,
//         formData,
//         { headers: { "Content-Type": "multipart/form-data" } }
//       );
//       alert("Product updated successfully!");
//       if (onUpdate) onUpdate(response.data);
//     } catch (error) {
//       console.error("Error updating product:", error);
//       alert("Failed to update product.");
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   return (
//     <div className="edit-product-container">
//       <form className="product-form font-sans text-[#848484] h-[95vh]" onSubmit={handleSubmit}>
//         <h2 className="text-3xl font-bold mt-4">Edit Product</h2>

//         {/* Product Details */}
//         <div className="form-section flex flex-col justify-start mt-2 w-[90%]">
//           <h3 className="mb-4 text-xl">Product Details</h3>
//           <div className="form-row w-[100%] flex justify-between text-lg">
//             <div className="w-[30%]">
//               <label className="text-lg">Name:</label>
//               <input
//                 type="text"
//                 name="name"
//                 className="w-[100%]"
//                 value={productData.name}
//                 onChange={handleInputChange}
//                 required
//               />
//             </div>
//             <div className="w-[30%]">
//               <label className="text-lg">Brand:</label>
//               <input
//                 type="text"
//                 name="brand"
//                 className="w-[100%]"
//                 value={productData.brand}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div className="w-[30%]">
//               <label className="text-lg">HSN:</label>
//               <input
//                 type="text"
//                 name="hsn"
//                 className="w-[100%]"
//                 value={productData.hsn}
//                 onChange={handleInputChange}
//                 required
//               />
//             </div>
//           </div>

//           {/* Categories and Subcategories */}
//           <div className="form-row w-[100%] flex justify-between text-lg">
//             <div className="w-[30%] mt-[-0.5rem]">
//               <label className="text-lg">Category:</label>
//               <select
//                 onChange={handleCategoryChange}
//                 value={selectedCategory}
//                 className="w-full px-3 py-2 border border-gray-300 bg-gray-100 text-sm"
//               >
//                 <option value="">Select a category</option>
//                 {categories.map((category) => (
//                   <option key={category.id} value={category.id}>
//                     {category.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="w-[30%] mt-[-0.5rem]">
//               <label className="text-lg">Subcategory:</label>
//               <select
//                 onChange={handleSubcategoryChange}
//                 value={selectedSubcategory}
//                 className="w-full px-3 py-2 border border-gray-300 bg-gray-100 text-sm"
//                 disabled={!subcategoriesList.length}
//               >
//                 <option value="">Select a subcategory</option>
//                 {subcategoriesList.map((subcategory) => (
//                   <option key={subcategory.id} value={subcategory.id}>
//                     {subcategory.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </div>
//         </div>

//         {/* Company Selection */}
//         <div className="form-section mt-4 w-[90%]">
//           <h3 className="font-sans text-xl">Companies</h3>
//           <div className="company-selection mb-4 mt-4 flex justify-between">
//             <button
//               type="button"
//               className="dropdown-button bg-[#C6C5B9] text-lg"
//               onClick={toggleDropdown}
//             >
//               Select Companies
//             </button>
//             {isDropdownOpen && (
//               <div className="dropdown-content">
//                 {companies.map((company) => (
//                   <div key={company.id} className="flex">
//                     <input
//                       type="checkbox"
//                       value={company.id}
//                       checked={productData.companyId.includes(company.id)}
//                       onChange={handleCheckboxChange}
//                     />
//                     <p>{company.companyName}</p>
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//         </div>

//         <button
//           type="submit"
//           className="bg-[#C6C5B9] text-[#848484] font-semibold"
//           disabled={isUploading}
//         >
//           {isUploading ? "Updating..." : "Update Product"}
//         </button>
//       </form>
//     </div>
//   );
// };

// export default EditProduct;





import React, { useState, useEffect } from "react";
import axios from "axios";

const EditProduct = ({ product, categories, companies, onUpdate }) => {
  const [productData, setProductData] = useState({ ...product });
  const [selectedCategory, setSelectedCategory] = useState(product.categoryId || "");
  const [selectedSubcategory, setSelectedSubcategory] = useState(product.subCategoryId || "");
  const [subcategoriesList, setSubcategoriesList] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    fetchSubCategories(product.categoryId);
  }, [product]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCategory(selectedValue);
    setSelectedSubcategory("");
    fetchSubCategories(selectedValue);
    setProductData((prev) => ({ ...prev, categoryId: selectedValue, subCategoryId: "" }));
  };

  const handleSubcategoryChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedSubcategory(selectedValue);
    setProductData((prev) => ({ ...prev, subCategoryId: selectedValue }));
  };

  const fetchSubCategories = (categoryId) => {
    const token = localStorage.getItem("token");
    axios
      .get(`https://emkayindia.in/api/v1/subcategory/all?categoryId=${categoryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setSubcategoriesList(response.data);
      })
      .catch((error) => console.error("Error fetching subcategories:", error));
  };

  const handleImageChange = (imageKey, file) => {
    setProductData((prev) => ({ ...prev, [imageKey]: file }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in productData) {
      if (key.startsWith("image") && productData[key] instanceof File) {
        formData.append(key, productData[key]);
      } else {
        formData.append(key, productData[key]);
      }
    }

    try {
      const response = await axios.patch(
        `https://emkayindia.in/api/v1/product/update/${product.id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      alert("Product updated successfully!");
      if (onUpdate) onUpdate(response.data);
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Failed to update product.");
    }
  };

  return (
    <div className="edit-product-container">
      <form className="product-form font-sans text-[#848484] h-[95h]" onSubmit={handleSubmit}>
        <h2 className="text-3xl font-bold mt-4">Edit Product</h2>

        {/* Product Details */}
        <div className="form-section flex flex-col justify-start mt-2 w-[90%]">
          <h3 className="mb-4 text-xl">Product Details</h3>
          <div className="form-row w-[100%] flex justify-between text-lg">
            <div className="w-[30%]">
              <label className="text-lg">Name:</label>
              <input
                type="text"
                name="name"
                className="w-[100%]"
                value={productData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="w-[30%]">
              <label className="text-lg">Brand:</label>
              <input
                type="text"
                name="brand"
                className="w-[100%]"
                value={productData.brand}
                onChange={handleInputChange}
              />
            </div>
            <div className="w-[30%]">
              <label className="text-lg">HSN:</label>
              <input
                type="text"
                name="hsn"
                className="w-[100%]"
                value={productData.hsn}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="form-row w-[100%] flex justify-between text-lg mt-4">
            <div className="w-[30%]">
              <label className="text-lg">Price:</label>
              <input
                type="number"
                name="price"
                className="w-[100%]"
                value={productData.price}
                onChange={handleInputChange}
              />
            </div>
            <div className="w-[30%]">
              <label className="text-lg">GST (%):</label>
              <input
                type="number"
                name="gst"
                className="w-[100%]"
                value={productData.gst}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Variants */}
          <div className="form-row w-[100%] flex justify-between text-lg mt-4">
            <div className="w-[30%]">
              <label className="text-lg">Variant:</label>
              <input
                type="text"
                name="variant"
                className="w-[100%]"
                value={productData.variant}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Categories and Subcategories */}
          <div className="form-row w-[100%] flex justify-between text-lg mt-4">
            <div className="w-[30%]">
              <label className="text-lg">Category:</label>
              <select
                onChange={handleCategoryChange}
                value={selectedCategory}
                className="w-full px-3 py-2 border border-gray-300 bg-gray-100 text-sm appearance-none"
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-[30%]">
              <label className="text-lg">Subcategory:</label>
              <select
                onChange={handleSubcategoryChange}
                value={selectedSubcategory}
                className="w-full px-3 py-2 border border-gray-300 bg-gray-100 text-sm appearance-none"
              >
                <option value="">Select a subcategory</option>
                {subcategoriesList.map((subcategory) => (
                  <option key={subcategory.id} value={subcategory.id}>
                    {subcategory.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Images */}
          <div className="form-section mt-4 w-[90%]">
            <h3 className="font-sans text-xl">Product Images</h3>
            {[...Array(8)].map((_, index) => {
              const imageKey = `image${index + 1}`;
              return (
                <div key={index} className="form-row mt-2 w-[100%]">
                  <label className="text-lg">{`Image ${index + 1}:`}</label>
                  <input
                    type="file"
                    onChange={(e) => handleImageChange(imageKey, e.target.files[0])}
                  />
                  {productData[imageKey] && (
                    <img
                      src={productData[imageKey] instanceof File ? URL.createObjectURL(productData[imageKey]) : productData[imageKey]}
                      alt={`Preview ${index + 1}`}
                      className="w-[100px] mt-2"
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <button
          type="submit"
          className="bg-[#C6C5B9] text-[#848484] font-semibold mt-4"
        >
          Update Product
        </button>
      </form>
    </div>
  );
};

export default EditProduct;
