// ProductDetails.js

import React from 'react';

const ProductDetails = ({ product, onBack }) => {
    return (
        <div className="product-details-container font-sans  mt-16  mx-auto text-[#848484] w-[100%]">
            <div className='w-[100%] flex justify-between'>
                <h2 className="text-3xl font-bold  mb-6">
                    {product.name} 
                </h2>
                <button
                    onClick={onBack}
                    className="bg-gray-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-gray-700 mb-4"
                >
                    Back to Products
                </button>
            </div>
            <div className="space-y-4">
                <p className="text-gray-600">
                    <strong className="">ID:</strong> {product.id}
                </p>
                <p className="text-gray-600">
                    <strong className="">Description:</strong> {product.description}
                </p>
                <p className="text-gray-600">
                    <strong className="">Additional Info:</strong> {product.description1}
                </p>
                <p className="text-gray-600">
                    <strong className="">Price:</strong> ${product.price}
                </p>
                <p className="text-gray-600">
                    <strong className="">Brand:</strong> {product.brand}
                </p>
                <p className="text-gray-600">
                    <strong className="">HSN:</strong> {product.hsn}
                </p>
                <div className="grid grid-cols-2 gap-4">

                    {[product.image1, product.image2, product.image3, product.image4]
                        .filter(Boolean)
                        .map((image, index) => (
                            <div className='flex '>
                                <p className='mr-4 w-[20%]'><strong>Image {index + 1}: </strong></p>
                                <img
                                    key={index}
                                    src={image}
                                    alt={`${product.name} variant ${index + 1}`}
                                    className="rounded-lg shadow-md w-[75%]"
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default ProductDetails;
