// import axios from 'axios';
// import { jwtDecode } from 'jwt-decode';
// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import AddCompany from './Pages/AddCompany';
// import './css/Dashboard.css'
// import CreateQuatation from './CreateQuatation';
// import AddProduct from './Pages/AddProduct';
// import CreateUser from './Pages/CreateUser';
// import { ToastContainer } from 'react-toastify';
// import QuotationList from './QuatationList';
// import AddClient from './AddClient';
// import UploadImage from './UploadImage';
// import AllClients from './AllClients';
// import {
//         FaBars, FaTimes, FaUserPlus, FaBuilding, FaFileInvoiceDollar, FaBox, FaUser, FaImage, FaUsers, FaSignOutAlt,
//     } from 'react-icons/fa';

// const hasPermission = (permissions, permissionName) => {
//     return permissions.some(permission => permission.name === permissionName);
// };
// const Dashboard = () => {
//     const navigate = useNavigate();
//     const [userName, setUserName] = useState("");
//     const [userPermissions, setUserPermissions] = useState([]);
//     const [userRole, setUserRole] = useState("");
//     const [activeComponent, setActiveComponent] = useState(null);
//     const [createUser, setCreateUser] = useState(false);
//     const [companies, setCompanies] = useState([]);
//     const [selectedCompany, setSelectedCompany] = useState(null);
//     const [terms, setTerms] = useState([]);
//     const [canCreateUser, setCanCreateUser] = useState(false);
//     const [canAddCompany, setCanAddCompany] = useState(false);
//     const [canAddProduct, setCanAddProduct] = useState(false);
//     const [canCreateQuatation, setCanCreateQuatatin] = useState(false);
//     const [canAddClient, setCanAddClient] = useState(false);


//     // Function to render the component based on button click
//     const toggleComponent = (componentName) => {
//         if (activeComponent === componentName) {
//             // If the clicked component is already active, deactivate it
//             setActiveComponent(null);
//         } else {
//             // Otherwise, activate the clicked component
//             setActiveComponent(componentName);
//         }
//     };
//     // Logout function
//     const onLogout = () => {
//         localStorage.clear();
//         // window.alert('Do you really want to leave? This will log you out.');
//         navigate('/');
//     };

//     // Event listener for browser back button
//     const handlePopState = (e) => {
//         // const confirmLogout = window.alert('Do you really want to leave? This will log you out.');
//         // if (confirmLogout) {
//         //     onLogout();  // Log out if confirmed
//         // } 
//         // else {
//         //     e.preventDefault();  // Prevent back navigation
//         //     window.history.pushState(null, '', window.location.href);  // Push state to prevent back
//         // }
//         onLogout();
//         navigate('/');

//     };

//     useEffect(() => {
//         const getUserDetails = async () => {
//             const token = localStorage.getItem('token');

//             if (!token) {
//                 console.error('No token found');
//                 onLogout();
//                 navigate('/login');
//                 return;
//             }

//             try {
//                 const decodedToken = jwtDecode(token); // Correct the jwtDecode usage
//                 const userId = decodedToken.id;
//                 console.log('UserID:', userId);

//                 const response = await axios.get(`https://emkayindia.in/api/v1/user/get/${userId}`, {
//                     headers: {
//                         'Authorization': `Bearer ${token}`,
//                         'Content-Type': 'application/json',
//                     },
//                 });
//                 axios.get('https://emkayindia.in/api/v1/company/all', {
//                     headers: { Authorization: `Bearer ${token}` }
//                 })
//                     .then(response => {
//                         if (response.data.length == 1) {
//                             setSelectedCompany(response.data[0]);
//                         }
//                         setCompanies(response.data)
//                     })
//                     .catch(error => console.error('Error fetching companies:', error));

//                 setUserName(response.data.name);
//                 setUserRole(response.data.role);
//                 setUserPermissions(response.data.permission);
//                 if (userPermissions) {
//                     console.log("User Permissions" + response?.data?.permission[0].name)
//                     console.log("CreaTe user Permission " + hasPermission(response.data.permission, "createUser"));
//                     console.log("AddCompany  Permission " + hasPermission(response.data.permission, "addCompany"));
//                     console.log("AddProduct  Permission " + hasPermission(response.data.permission, "addProduct"));
//                     console.log("CreateQuatation  Permission " + hasPermission(response.data.permission, "quotation"));
//                     setCanCreateUser(hasPermission(userPermissions, "createUser"));
//                     setCanAddCompany(hasPermission(userPermissions, "addCompany"));
//                     setCanAddProduct(hasPermission(userPermissions, "addProduct"));
//                     setCanCreateQuatatin(hasPermission(userPermissions, "quotation"));
//                     setCanAddClient(hasPermission(userPermissions, "addClient"))

//                 }

//                 console.log('User Data:', response.data);
//             } catch (err) {
//                 console.error('Get User Details Failed', err);
//                 onLogout();
//                 navigate('/login');
//             }
//         };

//         // Add event listener for back button
//         window.addEventListener('popstate',
//             () => {
//                 // window.alert('Do you really want to leave? This will log you out.');
//                 // if (confirmLogout) {
//                 //     onLogout();  // Log out if confirmed
//                 // } 
//                 // else {
//                 //     // preventDefault();  // Prevent back navigation
//                 //     window.history.pushState(null, '', window.location.href);  // Push state to prevent back
//                 // }
//                 onLogout();
//                 // handlePopState();

//             }
//         );

//         // Fetch user details
//         getUserDetails();

//         // Cleanup on component unmount
//         return () => {
//             window.removeEventListener('popstate', handlePopState);
//         };
//     }, [navigate]); // Empty dependency array to prevent infinite re-renders
//     const handleCompanyChange = (event) => {
//         const companyId = event.target.value;
//         const company = companies.find(c => c.id === companyId);
//         setTerms(company.terms.termsAndConditions);
//         setSelectedCompany(company);
//         console.log(terms)
//     };

//     return (
//         <div className='dashboard-container'>
//             <div className='header'>
//                 <p>Welcome  {userName}</p>
//                 {selectedCompany && <p>Selected Company :{selectedCompany.companyName}</p>}

//                 {userRole && (
//                     <h4>Role: {userRole}</h4>
//                 )}
//                 <button onClick={() => {
//                     onLogout();  // Clear authentication
//                     navigate('/');  // Redirect to Home page
//                 }}>
//                     Logout
//                 </button>
//             </div>
//             <div className="dashboard-main-container">
//                 <div className='side-bar text-white'>

//                     {/* <div onClick={() => toggleComponent(activeComponent)}>
//                         Dashboard
//                     </div>
//                     {(userRole == 'SUPERADMIN' || canCreateUser) && <div onClick={() => toggleComponent('CreateUser')}>
//                         Create User
//                     </div>
//                     }
//                     {(userRole == 'SUPERADMIN' || canAddCompany) && <div onClick={() => toggleComponent('AddCompany')}>
//                         Add Company
//                     </div>}
//                     {(userRole == 'SUPERADMIN' || canCreateQuatation) && <div onClick={() => toggleComponent('CreateQuatation')}>
//                         Create Quatation
//                     </div>}
//                     {(userRole == 'SUPERADMIN' || canAddProduct) && <div onClick={() => toggleComponent('AddProduct')}>
//                         Add Product
//                     </div>}
//                     {(userRole == 'SUPERADMIN' || canAddClient) && <div onClick={() => toggleComponent('AddClient')}>
//                         Add Client
//                     </div>}
//                     <div onClick={() => toggleComponent('UploadImage')}>
//                         Upload Image
//                     </div>
//                     <div onClick={() => toggleComponent('AllClients')}>
//                         All Clients
//                     </div>
//                     <div onClick={() => toggleComponent('AllUsers')}>
//                         All Users
//                     </div>
//                     <div onClick={() => {
//                         onLogout();  
//                     }}>
//                         Logout
//                     </div> */}
//                      {(userRole === 'SUPERADMIN' || canCreateUser) && (
//                         <button onClick={() => toggleComponent('CreateUser')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                             <FaUserPlus className="mr-2 " /> Create User
//                         </button>
//                     )}
//                     {(userRole === 'SUPERADMIN' || canAddCompany) && (
//                         <button onClick={() => toggleComponent('AddCompany')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                             <FaBuilding className="mr-2" /> Add Company
//                         </button>
//                     )}
//                     {(userRole === 'SUPERADMIN' || canCreateQuatation) && (
//                         <button onClick={() => toggleComponent('CreateQuatation')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                             <FaFileInvoiceDollar className="mr-2" /> Create Quatation
//                         </button>
//                     )}
//                     {(userRole === 'SUPERADMIN' || canAddProduct) && (
//                         <button onClick={() => toggleComponent('AddProduct')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                             <FaBox className="mr-2" /> Add Product
//                         </button>
//                     )}
//                     {(userRole === 'SUPERADMIN' || canAddClient) && (
//                         <button onClick={() => toggleComponent('AddClient')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                             <FaUser className="mr-2" /> Add Client
//                         </button>
//                     )}
//                     <button onClick={() => toggleComponent('UploadImage')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                         <FaImage className="mr-2" /> Upload Image
//                     </button>
//                     <button onClick={() => toggleComponent('AllClients')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                         <FaUsers className="mr-2" /> All Clients
//                     </button>
//                 </div>

//                 <div className='main-sec'>
//                     {/* <div>Select Company</div> */}
//                     {
//                         activeComponent == null && <div className="company-section">
//                             <label>Select Company:</label>
//                             <select onChange={handleCompanyChange}>
//                                 <option value="">Select a company</option>
//                                 {companies.map(company => (
//                                     <option key={company.id} value={company.id}>{company.companyName}</option>
//                                 ))}
//                             </select>
//                             {selectedCompany && (
//                                 <div className="company-details">
//                                     <p><strong>Name:</strong> {selectedCompany.companyName}</p>
//                                     <p><strong>GST No:</strong> {selectedCompany.gstNo}</p>
//                                     <p><strong>Email:</strong> {selectedCompany.email}</p>
//                                     <p><strong>Phone:</strong> {selectedCompany.phone}</p>
//                                     <p><strong>Address:</strong> {selectedCompany.address}</p>
//                                     {/* {billType=="pi" && <div>
//                               <p><strong>Account Name:</strong> {selectedCompany.accountName}</p>
//                               <p><strong>Bank Name:</strong> {selectedCompany.bankName}</p>
//                               <p><strong>Account Number:</strong> {selectedCompany}</p>
//                               </div>} */}
//                                 </div>
//                             )}
//                         </div>
//                     }
// {(userRole === 'SUPERADMIN' || userRole === 'ADMIN') && activeComponent === 'CreateUser' && <CreateUser toggleComponent={toggleComponent} userRole={userRole} />}
// {activeComponent === 'AddCompany' && <AddCompany toggleComponent={toggleComponent} />}
// {activeComponent === 'CreateQuatation' && <CreateQuatation toggleComponent={toggleComponent} selectedCompany={selectedCompany} />}
// {activeComponent === 'AddProduct' && <AddProduct toggleComponent={toggleComponent} />}
// {activeComponent === 'QuatationList' && <QuotationList toggleComponent={toggleComponent} selectedCompany={selectedCompany} />}
// {activeComponent === 'AddClient' && <AddClient toggleComponent={toggleComponent} selectedCompany={selectedCompany} />}
// {activeComponent === 'UploadImage' && <UploadImage toggleComponent={toggleComponent} />}
// {activeComponent === 'AllClients' && <AllClients toggleComponent={toggleComponent} />}
//                 </div>
//             </div>
//             <ToastContainer />
//         </div>
//     );
// };

// export default Dashboard;







// import axios from 'axios';
// import { jwtDecode } from 'jwt-decode';
// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import AddCompany from './Pages/AddCompany';
// import './css/Dashboard.css';
// import CreateQuatation from './CreateQuatation';
// import AddProduct from './Pages/AddProduct';
// import CreateUser from './Pages/CreateUser';
// import { ToastContainer } from 'react-toastify';
// import QuotationList from './QuatationList';
// import AddClient from './AddClient';
// import UploadImage from './UploadImage';
// import AllClients from './AllClients';
// import {
//     FaBars, FaTimes, FaUserPlus, FaBuilding, FaFileInvoiceDollar, FaBox, FaUser, FaImage, FaUsers, FaSignOutAlt,
// } from 'react-icons/fa';

// const hasPermission = (permissions, permissionName) => {
//     return permissions.some(permission => permission.name === permissionName);
// };

// const Dashboard = () => {
//     const navigate = useNavigate();
//     const [userName, setUserName] = useState("");
//     const [userPermissions, setUserPermissions] = useState([]);
//     const [userRole, setUserRole] = useState("");
//     const [activeComponent, setActiveComponent] = useState(null);
//     const [companies, setCompanies] = useState([]);
//     const [selectedCompany, setSelectedCompany] = useState(null);
//     const [terms, setTerms] = useState([]);
//     const [canCreateUser, setCanCreateUser] = useState(false);
//     const [canAddCompany, setCanAddCompany] = useState(false);
//     const [canAddProduct, setCanAddProduct] = useState(false);
//     const [canCreateQuatation, setCanCreateQuatation] = useState(false);
//     const [canAddClient, setCanAddClient] = useState(false);

//     const [openCategory, setOpenCategory] = useState(null); // For expanding/collapsing categories

//     // Function to render the component based on button click
//     const toggleComponent = (componentName) => {
//         if (activeComponent === componentName) {
//             setActiveComponent(null); // Collapse if clicked again
//         } else {
//             setActiveComponent(componentName); // Expand the clicked component
//         }
//     };

//     // Function to toggle category expansion/collapse
//     const toggleCategory = (categoryName) => {
//         if (openCategory === categoryName) {
//             setOpenCategory(null); // Collapse the category if it's already open
//         } else {
//             setOpenCategory(categoryName); // Expand the clicked category
//         }
//     };

//     // Logout function
//     const onLogout = () => {
//         localStorage.clear();
//         navigate('/');
//     };

//     useEffect(() => {
//         const getUserDetails = async () => {
//             const token = localStorage.getItem('token');

//             if (!token) {
//                 console.error('No token found');
//                 onLogout();
//                 navigate('/login');
//                 return;
//             }

//             try {
//                 const decodedToken = jwtDecode(token); 
//                 const userId = decodedToken.id;
//                 console.log('UserID:', userId);

//                 const response = await axios.get(`https://emkayindia.in/api/v1/user/get/${userId}`, {
//                     headers: {
//                         'Authorization': `Bearer ${token}`,
//                         'Content-Type': 'application/json',
//                     },
//                 });

//                 axios.get('https://emkayindia.in/api/v1/company/all', {
//                     headers: { Authorization: `Bearer ${token}` }
//                 })
//                     .then(response => {
//                         if (response.data.length === 1) {
//                             setSelectedCompany(response.data[0]);
//                         }
//                         setCompanies(response.data);
//                     })
//                     .catch(error => console.error('Error fetching companies:', error));

//                 setUserName(response.data.name);
//                 setUserRole(response.data.role);
//                 setUserPermissions(response.data.permission);

//                 if (userPermissions) {
//                     setCanCreateUser(hasPermission(userPermissions, "createUser"));
//                     setCanAddCompany(hasPermission(userPermissions, "addCompany"));
//                     setCanAddProduct(hasPermission(userPermissions, "addProduct"));
//                     setCanCreateQuatation(hasPermission(userPermissions, "quotation"));
//                     setCanAddClient(hasPermission(userPermissions, "addClient"));
//                 }
//             } catch (err) {
//                 console.error('Get User Details Failed', err);
//                 onLogout();
//                 navigate('/login');
//             }
//         };

//         window.addEventListener('popstate', () => {
//             onLogout();
//             navigate('/');
//         });

//         getUserDetails();

//         return () => {
//             window.removeEventListener('popstate', onLogout);
//         };
//     }, [navigate]);

//     const handleCompanyChange = (event) => {
//         const companyId = event.target.value;
//         const company = companies.find(c => c.id === companyId);
//         setTerms(company.terms.termsAndConditions);
//         setSelectedCompany(company);
//     };

//     return (
//         <div className='dashboard-container'>
//             <div className='header'>
//                 <p>Welcome {userName}</p>
//                 {selectedCompany && <p>Selected Company: {selectedCompany.companyName}</p>}
//                 {userRole && <h4>Role: {userRole}</h4>}
//                 <button onClick={onLogout}>Logout</button>
//             </div>

//             <div className="dashboard-main-container">
//                 <div className="side-bar text-white">
//                     {/* User Category */}
//                     <div>
//                         <button onClick={() => toggleCategory('User')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                             <FaUserPlus className="mr-2" /> User
//                         </button>
//                         {openCategory === 'User' && (
//                             <div className="ml-4">
//                                 {(userRole === 'SUPERADMIN' || canCreateUser) && (
//                                     <button onClick={() => toggleComponent('CreateUser')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                                         <FaUserPlus className="mr-2" /> Create User
//                                     </button>
//                                 )}
//                             </div>
//                         )}
//                     </div>

//                     {/* Company Category */}
//                     <div>
//                         <button onClick={() => toggleCategory('Company')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                             <FaBuilding className="mr-2" /> Company
//                         </button>
//                         {openCategory === 'Company' && (
//                             <div className="ml-4">
//                                 {(userRole === 'SUPERADMIN' || canAddCompany) && (
//                                     <button onClick={() => toggleComponent('AddCompany')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                                         <FaBuilding className="mr-2" /> Add Company
//                                     </button>
//                                 )}
//                             </div>
//                         )}
//                     </div>

//                     {/* Quotation Category */}
//                     <div>
//                         <button onClick={() => toggleCategory('Quotation')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                             <FaFileInvoiceDollar className="mr-2" /> Quotation
//                         </button>
//                         {openCategory === 'Quotation' && (
//                             <div className="ml-4">
//                                 {(userRole === 'SUPERADMIN' || canCreateQuatation) && (
//                                     <button onClick={() => toggleComponent('CreateQuatation')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                                         <FaFileInvoiceDollar className="mr-2" /> Create Quotation
//                                     </button>
//                                 )}
//                             </div>
//                         )}
//                     </div>

//                     {/* Product Category */}
//                     <div>
//                         <button onClick={() => toggleCategory('Product')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                             <FaBox className="mr-2" /> Product
//                         </button>
//                         {openCategory === 'Product' && (
//                             <div className="ml-4">
//                                 {(userRole === 'SUPERADMIN' || canAddProduct) && (
//                                     <button onClick={() => toggleComponent('AddProduct')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                                         <FaBox className="mr-2" /> Add Product
//                                     </button>
//                                 )}
//                             </div>
//                         )}
//                     </div>

//                     {/* Client Category */}
//                     <div>
//                         <button onClick={() => toggleCategory('Client')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                             <FaUser className="mr-2" /> Client
//                         </button>
//                         {openCategory === 'Client' && (
//                             <div className="ml-4">
//                                 {(userRole === 'SUPERADMIN' || canAddClient) && (
//                                     <button onClick={() => toggleComponent('AddClient')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                                         <FaUser className="mr-2" /> Add Client
//                                     </button>
//                                 )}
//                                 <button onClick={() => toggleComponent('AllClients')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                                     <FaUsers className="mr-2" /> All Clients
//                                 </button>
//                             </div>
//                         )}
//                     </div>

//                     {/* Other Options */}
//                     <button onClick={() => toggleComponent('UploadImage')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center text-white">
//                         <FaImage className="mr-2" /> Upload Image
//                     </button>
//                 </div>

//                 <div className="main-sec">
//                     {/* Rendering the component based on what is selected */}
//                     {activeComponent === 'CreateUser' && <CreateUser />}
//                     {activeComponent === 'AddCompany' && <AddCompany />}
//                     {activeComponent === 'CreateQuatation' && <CreateQuatation />}
//                     {activeComponent === 'AddProduct' && <AddProduct />}
//                     {activeComponent === 'AddClient' && <AddClient />}
//                     {activeComponent === 'UploadImage' && <UploadImage />}
//                     {activeComponent === 'AllClients' && <AllClients />}
//                 </div>

//                 <ToastContainer />
//             </div>
//         </div>
//     );
// };

// export default Dashboard;


// ---------------------------------------
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddCompany from './Pages/AddCompany';
import './css/Dashboard.css';
import CreateQuatation from './CreateQuatation';
import AddProduct from './Pages/AddProduct';
import CreateUser from './Pages/CreateUser';
import { toast, ToastContainer } from 'react-toastify';
import QuotationList from './QuatationList';
import AddClient from './AddClient';
import UploadImage from './UploadImage';
import AllClients from './AllClients';
import { AiFillHome } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import {
    FaBars, FaTimes, FaUserPlus, FaBuilding, FaFileInvoiceDollar, FaBox, FaUser, FaImage, FaUsers, FaSignOutAlt,
    FaArrowDown,
    FaChevronDown,
    FaCog,
} from 'react-icons/fa';
import BulkProductUpload from './BulkProductUpload';
import AllCompanies from './AllCompanies';
import AllUsers from './AllUsers';
import AllCategories from './AllCategories';
import AllProducts from './AllProducts';
import CreatePI from './CreatePI';

const hasPermission = (permissions, permissionName) => {
    return permissions.some(permission => permission.name === permissionName);
};

const Dashboard = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState("");
    const [userPermissions, setUserPermissions] = useState([]);
    const [userRole, setUserRole] = useState("");
    const [activeComponent, setActiveComponent] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [terms, setTerms] = useState([]);
    const [canCreateUser, setCanCreateUser] = useState(false);
    const [canAddCompany, setCanAddCompany] = useState(false);
    const [canAddProduct, setCanAddProduct] = useState(false);
    const [canCreateQuatation, setCanCreateQuatation] = useState(false);
    const [canAddClient, setCanAddClient] = useState(false);

    const [openCategory, setOpenCategory] = useState(null); // For expanding/collapsing categories



    // Function to render the component based on button click
    const toggleComponent = (componentName) => {
        if (activeComponent === componentName) {
            setActiveComponent(null); // Collapse if clicked again
        } else {
            setActiveComponent(componentName); // Expand the clicked component
        }
    };
    const token = localStorage.getItem('token')
    useEffect(() => {
        toast.success("Login Success");
    }, [token])

    // Function to toggle category expansion/collapse
    const toggleCategory = (categoryName) => {
        if (openCategory === categoryName) {
            setOpenCategory(null); // Collapse the category if it's already open
        } else {
            setOpenCategory(categoryName); // Expand the clicked category
        }
    };

    // Logout function
    const onLogout = () => {
        localStorage.clear();
        window.close();
        navigate('/');
    };

    useEffect(() => {
        const getUserDetails = async () => {
            const token = localStorage.getItem('token');

            if (!token) {
                console.error('No token found');
                onLogout();
                navigate('/login');
                return;
            }

            try {
                const decodedToken = jwtDecode(token);
                const userId = decodedToken.id;
                console.log('UserID:', userId);

                const response = await axios.get(`https://emkayindia.in/api/v1/user/get/${userId}`, {
                    // const response = await axios.get(`https://emkayindia.in/api/v1/user/get/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                axios.get('https://emkayindia.in/api/v1/company/all', {
                    headers: { Authorization: `Bearer ${token}` }
                })
                    .then(response => {
                        if (response.data.length === 1) {
                            setSelectedCompany(response.data[0]);
                        }
                        setCompanies(response.data);
                    })
                    .catch(error => console.error('Error fetching companies:', error));

                setUserName(response.data.name);
                setUserRole(response.data.role);
                setUserPermissions(response.data.permission);

                if (userPermissions) {
                    setCanCreateUser(hasPermission(userPermissions, "createUser"));
                    setCanAddCompany(hasPermission(userPermissions, "addCompany"));
                    setCanAddProduct(hasPermission(userPermissions, "addProduct"));
                    setCanCreateQuatation(hasPermission(userPermissions, "quotation"));
                    setCanAddClient(hasPermission(userPermissions, "addClient"));
                }
            } catch (err) {
                console.error('Get User Details Failed', err);
                onLogout();
                navigate('/login');
            }
        };

        window.addEventListener('popstate', () => {
            onLogout();
            navigate('/');
        });

        getUserDetails();

        return () => {
            window.removeEventListener('popstate', onLogout);
        };
    }, [navigate]);

    const fetchCompanies = () => {
        const token = localStorage.getItem('token');
        axios.get('https://emkayindia.in/api/v1/company/all', {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => {
                if (response.data.length === 1) {
                    setSelectedCompany(response.data[0]);
                }
                console.log(response.data);
                setCompanies(response.data);
            })
            .catch(error => console.error('Error fetching companies:', error));
    }

    const handleCompanyChange = (event) => {
        const companyId = event.target.value;
        const company = companies.find(c => c.id === companyId);
        setTerms(company.terms.termsAndConditions);
        setSelectedCompany(company);
    };

    return (
        <div className=' bg-white  over h-[100vh] w-[100%] overflow-hidden font-sans'>
            <div className=' flex flex-col bg-[#9cafb6] p-3 text-white h-[8%] fixed '>
                <div className="flex justify-between items-center w-[98vw] h-[100%]">
                    {/* <p className="text-xl font-semibold">Welcome, {userName}</p> */}
                    {selectedCompany ? <p className="text-2xl font-bold "> {selectedCompany.companyName}</p> : <p className='text-2xl font-bold'>Welcome</p>}
                    {/* {userRole && <p className="text-md font-medium">Role: {userRole}</p>} */}
                    {/* <p className='text-2xl font-bold'>{activeComponent}</p> */}
                    <button
                        onClick={onLogout}
                        className=" font-bold font-sans px-8  text-lg h-[100%] mr-4 bg-[#ffb321] rounded transition duration-300 text-black"
                    >
                        Logout
                    </button>
                </div>
            </div>

            <div className="flex h-[91vh] mt-12">
                <div className=" w-64 h-[94vh] bg-[#4e4e4e] p-4 text-white ">
                    <p className="text-xl font-semibold mt-8">{userName}</p>
                    {/* User Category */}
                    <button onClick={() => toggleComponent(activeComponent)} className="flex items-center  w-full py-3 px-4 text-lg hover:bg-gray-700 transition duration-300 rounded ">
                        <AiFillHome size={20} className='mr-2' />      Dashboard
                    </button>

                    <div>
                        {/* <button
                            onClick={() => toggleCategory('User')}
                            className="flex items-center  w-full py-3 px-4 text-lg hover:bg-gray-700 transition duration-300 rounded"
                        >
                            <FaUserPlus className="mr-2" />   User
                            <FaChevronDown
                                className={`ml-auto font-normal size-4 transform transition-transform opacity-50 duration-300 ${openCategory === 'User' ? 'rotate-180' : 'rotate-0'}`}
                            />
                            <div />
                        </button> */}


                    </div>

                    {/* Company Category */}
                    <div>
                        {/* <button
                            onClick={() => toggleCategory('Company')}
                            className="flex items-center  w-full py-3 px-4 text-lg hover:bg-gray-700 transition duration-300 rounded"
                        >
                            <FaBuilding className="mr-2" /> Company
                            <FaChevronDown
                                className={`ml-auto font-normal size-4 transform transition-transform opacity-50 duration-300 ${openCategory === 'Company' ? 'rotate-180' : 'rotate-0'}`}
                            />
                            <div />
                        </button> */}

                    </div>

                    {/* Quotation Category */}
                    {/* <div>
                        <button
                            onClick={() => toggleCategory('Quotation')}
                            className="flex items-center w-full py-3 px-4 text-lg hover:bg-gray-700 transition duration-300 rounded"
                        >
                            <FaFileInvoiceDollar className="mr-2" /> Quotation
                            <FaChevronDown
                                className={`ml-auto font-normal size-4 transform transition-transform opacity-50 duration-300 ${openCategory === 'Quotation' ? 'rotate-180' : 'rotate-0'}`}
                            />
                            <div />
                        </button>
                        <div
                            className={`transition-all duration-500 ease-in-out ${openCategory === 'Quotation' ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}
                        >
                            {(userRole === 'SUPERADMIN' || canCreateQuatation) && (
                                <button
                                    onClick={() => toggleComponent('CreateQuatation')}
                                    className="py-2 px-4 ml-4 hover:bg-gray-700 flex items-center rounded transition duration-300"
                                >
                                    <FaFileInvoiceDollar className="mr-2" /> Create Quotation
                                </button>
                            )}
                        </div>
                    </div> */}
                    <div
                    // className={`transition-all duration-500 ease-in-out ${openCategory === 'Quotation' ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}
                    >
                        {(userRole === 'SUPERADMIN' || canCreateQuatation) && (
                            <button
                                onClick={() => activeComponent !== "CreateQuatation" &&  toggleComponent('CreateQuatation')}
                                className={` flex className="flex items-center  w-full py-3 px-4 text-lg rounded  ${
                                    activeComponent === "CreateQuatation" ? "bg-gray-200 text-black" : ""
                                  }`}
                            >
                                <FaFileInvoiceDollar className="mr-2" /> Create Quotation
                            </button>
                        )}
                        {/* {(userRole === 'SUPERADMIN' || canCreateQuatation) && (
                            <button
                                onClick={() => activeComponent !== "CreatePI" &&  toggleComponent('CreateQuatation')}
                                className={`py-2 px-2 ml-4 flex items-center rounded transition duration-300 w-[75%] ${
                                    activeComponent === "CreatePI" ? "bg-gray-200 text-black" : ""
                                  }`}
                            >
                                <FaFileInvoiceDollar className="mr-2" /> Create PI
                            </button>
                        )} */}
                    </div>
                    <div
                    // className={`transition-all duration-500 ease-in-out ${openCategory === 'Quotation' ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}
                    >
                        {(userRole === 'SUPERADMIN' || canCreateQuatation) && (
                            <button
                                onClick={() => activeComponent !== "CreatePI" &&  toggleComponent('CreatePI')}
                                className={`flex className="flex items-center  w-full py-3 px-4 text-lg  rounded  ${
                                    activeComponent === "CreatePI" ? "bg-gray-200 text-black" : ""
                                  }`}
                            >
                                <FaFileInvoiceDollar className="mr-2" /> Create PI
                            </button>
                        )}
                        {/* {(userRole === 'SUPERADMIN' || canCreateQuatation) && (
                            <button
                                onClick={() => activeComponent !== "CreatePI" &&  toggleComponent('CreateQuatation')}
                                className={`py-2 px-2 ml-4 flex items-center rounded transition duration-300 w-[75%] ${
                                    activeComponent === "CreatePI" ? "bg-gray-200 text-black" : ""
                                  }`}
                            >
                                <FaFileInvoiceDollar className="mr-2" /> Create PI
                            </button>
                        )} */}
                    </div>

                    {/* Product Category */}
                    <div>
                        <button
                            onClick={() => toggleCategory('Product')}
                            className="flex items-center  w-full py-3 px-4 text-lg hover:bg-gray-700 transition duration-300 rounded"
                        >
                            <FaBox className="mr-2" /> Product
                            <FaChevronDown
                                className={`ml-auto font-normal size-4 transform transition-transform opacity-50 duration-300 ${openCategory === 'Product' ? 'rotate-180' : 'rotate-0'}`}
                            />
                            <div />
                        </button>
                        <div
                            className={`transition-all duration-500 ease-in-out ${openCategory === 'Product' ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}
                        >
                            {(userRole === 'SUPERADMIN' || canAddProduct) && (
                                <button
                                    onClick={() => activeComponent !== "AddProduct" &&  toggleComponent('AddProduct')}
                                    className={`py-2 px-4 ml-4 flex items-center rounded transition duration-300 w-[75%] ${
                                        activeComponent === "AddProduct" ? "bg-gray-200 text-black" : ""
                                      }`}
                                >
                                    <FaBox className="mr-2" /> Add Product
                                </button>
                            )}

                            <button
                                onClick={() => activeComponent !== "AllCategories" &&  toggleComponent('AllCategories')}
                                className={`py-2 px-4 ml-4 flex items-center rounded transition duration-300 w-[75%] ${
                                    activeComponent === "AllCategories" ? "bg-gray-200 text-black" : ""
                                  }`}
                            >
                                <FaBox className="mr-2" /> Add Categories
                            </button>
                            <button
                                onClick={() => activeComponent !== "AllProducts" &&  toggleComponent('AllProducts')}
                                className={`py-2 px-4 ml-4 flex items-center rounded transition duration-300 w-[75%] ${
                                    activeComponent === "AllProducts" ? "bg-gray-200 text-black" : ""
                                  }`}
                            >
                                <FaBox className="mr-2" /> All Products
                            </button>
                            <button onClick={() => activeComponent !== "UploadImage" &&  toggleComponent('UploadImage')} 
                            className={`py-2 px-4 ml-4 flex items-center rounded transition duration-300 w-[75%] ${
                                activeComponent === "UploadImage" ? "bg-gray-200 text-black" : ""
                              }`}>
                                <FaImage className="mr-2" /> Upload Image
                            </button>
                            <button
                                onClick={() => activeComponent !== "BulkProductUpload" &&  toggleComponent('BulkProductUpload')}
                                className={`py-2 px-4 ml-4 flex items-center rounded transition duration-300 w-[75%] ${
                                    activeComponent === "BulkProductUpload" ? "bg-gray-200 text-black" : ""
                                  }`}
                            >
                                <FaBox className="mr-2" /> Bulk Upload
                            </button>
                        </div>
                    </div>

                    {/* Client Category */}
                    <div>
                        <button
                            onClick={() => toggleCategory('Client')}
                            className="flex items-center   w-full py-3 px-4 text-lg hover:bg-gray-700 transition duration-300 rounded"
                        >
                            <FaUsers className="mr-2" /> Client
                            <FaChevronDown
                                className={`ml-auto font-normal size-4 transform transition-transform opacity-50 duration-300 ${openCategory === 'Client' ? 'rotate-180' : 'rotate-0'}`}
                            />
                            <div />
                        </button>
                        <div
                            className={`transition-all duration-500 ease-in-out ${openCategory === 'Client' ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}
                        >
                            {(userRole === 'SUPERADMIN' || canAddClient) && (
                                <button
                                    onClick={() => activeComponent !== "AddClient" &&  toggleComponent('AddClient')}
                                    className={`py-2 px-4 ml-4 flex items-center rounded transition duration-300 w-[75%] ${
                                        activeComponent === "AddClient" ? "bg-gray-200 text-black" : ""
                                      }`}
                                >
                                    <FaUser className="mr-2" /> Add Client
                                </button>
                            )}
                            <button
                                onClick={() => activeComponent !== "AllClients" &&  toggleComponent('AllClients')}
                                className={`py-2 px-4 ml-4 flex items-center rounded transition duration-300 w-[75%] ${
                                    activeComponent === "AllClients" ? "bg-gray-200 text-black" : ""
                                  }`}
                            >
                                <FaUsers className="mr-2" /> View All Clients
                            </button>
                        </div>
                    </div>

                    <div>
                        <button
                            onClick={() => toggleCategory('Settings')}
                            className="flex items-center w-full py-3 px-4 text-lg hover:bg-gray-700 transition duration-300 rounded"
                        >
                            <FaCog className="mr-2" />Settings
                            <FaChevronDown
                                className={`ml-auto font-normal size-4 transform transition-transform opacity-50 duration-300 ${openCategory === 'Settings' ? 'rotate-180' : 'rotate-0'}`}
                            />
                            <div />
                        </button>
                        <div
                            className={`transition-all duration-500 ease-in-out ${openCategory === 'Settings' ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}
                        >
                            
                            <div
                                className={`transition-all duration-500 ease-in-out `}
                            >
                                {(userRole === 'SUPERADMIN' || canCreateUser) && (
                                    <button
                                        onClick={() => activeComponent !== "CreateUser" &&  toggleComponent('CreateUser')}
                                        className={`py-2 px-4 ml-4 flex items-center rounded transition duration-300 w-[75%] ${
                                            activeComponent === "CreateUser" ? "bg-gray-200 text-black" : ""
                                          }`}
                                    >
                                        <FaUserPlus className="mr-2" /> Create User
                                    </button>
                                )}
                            </div>

                            <div
                                className={`transition-all duration-500 ease-in-out `}
                            >
                                {(
                                    <button
                                        onClick={() => activeComponent !== "AllUsers" && toggleComponent('AllUsers')}
                                        className={`py-2 px-4 ml-4 flex items-center rounded transition duration-300 w-[75%] ${
                                            activeComponent === "AllUsers" ? "bg-gray-200 text-black" : ""
                                          }`}
                                    >
                                        <FaUserPlus className="mr-2" /> All Users
                                    </button>
                                )}
                            </div>
                            <div
                            className={`transition-all duration-500 ease-in-out  `}
                        >
                            {(userRole === 'SUPERADMIN' || canAddCompany) && (
                                <button
                                    onClick={() => activeComponent !== "AddCompany" && toggleComponent("AddCompany")}
                                    className={`py-2 px-4 ml-4 flex items-center rounded transition duration-300 w-[75%] ${
                                        activeComponent === "AddCompany" ? "bg-gray-200 text-black" : ""
                                      }`}
                                >
                                    <FaBuilding className="mr-2" /> Add Company
                                </button>
                            )}
                            {
                                <button
                                    onClick={() => activeComponent !== "AllCompanies" && toggleComponent('AllCompanies')}
                                    className={`py-2 px-4 ml-4 flex items-center rounded transition duration-300 w-[75%] ${
                                        activeComponent === "AllCompanies" ? "bg-gray-200 text-black" : ""
                                      }`}
                                >
                                    <FaBuilding className="mr-2" /> All Company
                                </button>
                            }
                        </div>
                        </div>
                        
                    </div>

                    {/* Other Options */}

                    <button onClick={onLogout} className="flex items-center  w-full py-3 px-4 text-lg hover:bg-gray-700 transition duration-300 rounded ">
                        <MdLogout size={24} className='mr-2' />      Logout
                    </button>
                </div>
                {activeComponent != null && <div className="flex-1 bg-[#f9f9f9] ">
                    {/* Rendering the component based on what is selected */}
                    {/* {activeComponent === 'CreateUser' && <CreateUser />}
                    {activeComponent === 'AddCompany' && <AddCompany />}
                    {activeComponent === 'CreateQuatation' && <CreateQuatation />}
                    {activeComponent === 'AddProduct' && <AddProduct />}
                    {activeComponent === 'AddClient' && <AddClient />}
                    {activeComponent === 'UploadImage' && <UploadImage />}
                    {activeComponent === 'AllClients' && <AllClients />} */}
                    {(userRole === 'SUPERADMIN' || userRole === 'ADMIN') && activeComponent === 'CreateUser' && <CreateUser toggleComponent={toggleComponent} userRole={userRole} />}
                    {activeComponent === 'AddCompany' && <AddCompany toggleComponent={toggleComponent} />}
                    {activeComponent === 'CreateQuatation' && <CreateQuatation toggleComponent={toggleComponent} selectedCompany={selectedCompany} />}
                    {activeComponent === 'AddProduct' && <AddProduct toggleComponent={toggleComponent} />}
                    {activeComponent === 'QuatationList' && <QuotationList toggleComponent={toggleComponent} selectedCompany={selectedCompany} />}
                    {activeComponent === 'AddClient' && <AddClient toggleComponent={toggleComponent} selectedCompany={selectedCompany} />}
                    {activeComponent === 'UploadImage' && <UploadImage toggleComponent={toggleComponent} />}
                    {activeComponent === 'AllClients' && <AllClients toggleComponent={toggleComponent}  selectedCompany2={selectedCompany}/>}
                    {activeComponent === 'BulkProductUpload' && <BulkProductUpload toggleComponent={toggleComponent} />}
                    {activeComponent === 'AllCompanies' && <AllCompanies toggleComponent={toggleComponent} />}
                    {activeComponent === 'AllUsers' && <AllUsers toggleComponent={toggleComponent} />}
                    {activeComponent === 'AllCategories' && <AllCategories toggleComponent={toggleComponent} />}
                    {activeComponent === 'AllProducts' && <AllProducts toggleComponent={toggleComponent} />}
                    {activeComponent === 'CreatePI' && <CreatePI toggleComponent={toggleComponent} selectedCompany={selectedCompany} />}
                </div>}
                {
                    activeComponent == null && (
                        <div
                            className="company-section w-[50%] h-[88vh] top-16  p-6 "
                            onClick={fetchCompanies}
                        >
                            <div className='w-[100%]  flex  justify-center items-center'>
                                <label className="block text-gray-700 font-medium mb-2">Select Company:</label>
                                <select
                                    onChange={handleCompanyChange}
                                    className="w-[50%]  border border-gray-300 rounded-lg px-3 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                >
                                    <option value="">Select a company</option>
                                    {companies.map(company => (
                                        <option key={company.id} value={company.id}>
                                            {company.companyName}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {selectedCompany && (
                                <div className='w-[100%] h-[50%] flex justify-between mt-10'>
                                    {/* <div className='  h-[50%] w-[50%] '>
                                        <div className=" h-[100%] space-y-3">
                                            <p><span className='font-semibold'>Comapany Name:</span> {selectedCompany.companyName}</p>
                                            
                                            <span className='font-bold mt-2'>Address: </span>
                                            {selectedCompany.address && (
                                                <>
                                                    {selectedCompany.address.split("0/").map((line, index) => (
                                                        (index==0 ? <span key={index}> {line.trim()}</span>: <p key={index}> {line.trim()}</p>)
                                                    ))}

                                                    <p>City: {selectedCompany.city}</p>
                                                    <p>Pincode: {selectedCompany.pincode}</p>
                                                    <p>State: {selectedCompany.state}</p>
                                                </>

                                            )}
                                        </div>

                                    </div> */}
                                    <div className="h-[50%] w-[50%]   ">
                                        <div className="h-full space-y-2 ">
                                            <p>
                                                <span className="font-bold">Company Name:</span> {selectedCompany.companyName}
                                            </p>
                                            <p>
                                                <span className="font-bold">Address:</span>{" "}
                                                {selectedCompany.address && selectedCompany.address.split("/0")[0].trim()}
                                                <br/>
                                                {selectedCompany.address && selectedCompany.address.split("/0")[1].trim()}
                                            </p>
                                            {selectedCompany.address &&
                                                selectedCompany.address.split("0/").slice(1).map((line, index) => (
                                                    <p key={index}>{line.trim()}</p>
                                                ))}
                                            <p ><span className='font-semibold'>City: </span>{selectedCompany.city}</p>
                                            <p><span className='font-semibold'>Pincode: </span>{selectedCompany.pincode}</p>
                                            <p><span className='font-semibold'>State: </span>{selectedCompany.state}</p>
                                        </div>
                                    </div>

                                    <div className=" text-gray-700   h-[80%] flex flex-col w-fit mt-1">
                                        <div className=''>
                                            <p className="">
                                                <strong>Email:</strong>
                                            </p>
                                            <div className='border bg-gray-200  p-2'>
                                                <p> {selectedCompany.email}</p>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <p className="">
                                                <strong>Phone:</strong>
                                            </p>
                                            <div className='border bg-gray-200 p-2'>
                                                <p> {selectedCompany.phone}</p>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <p className="">
                                                <strong>GST No.:</strong>
                                            </p>
                                            <div className='border bg-gray-200 p-2'>
                                                <p> {selectedCompany.gstNo}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="flex space-x-4 mb-6">
                                        {(userRole === 'SUPERADMIN' || canCreateQuatation) && (
                                            <button
                                                onClick={() => toggleComponent('CreateQuatation')}
                                                className="bg-yellow-400 text-black font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out hover:bg-yellow-500"
                                            >
                                                New Quotation
                                            </button>
                                        )}
                                        {(userRole === 'SUPERADMIN' || canCreateQuatation) && (
                                            <button
                                                onClick={() => toggleComponent('CreateQuatation')}
                                                className="bg-teal-500 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out hover:bg-teal-600"
                                            >
                                                New Proforma Invoice
                                            </button>
                                        )}
                                    </div> */}
                                </div>

                            )}
                            {selectedCompany && <div className="flex justify-around mb-6 w-[100%]">
                                {(userRole === 'SUPERADMIN' || canCreateQuatation) && (
                                    <button
                                        onClick={() => toggleComponent('CreateQuatation')}
                                        className="bg-yellow-400 text-black font-semibold py-2 px-10 rounded-lg shadow-lg transition duration-300 ease-in-out hover:bg-yellow-500"
                                    >
                                        New Quotation
                                    </button>
                                )}
                                {(userRole === 'SUPERADMIN' || canCreateQuatation) && (
                                    <button
                                        onClick={() => toggleComponent('CreatePI')}
                                        className="bg-teal-500 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out hover:bg-teal-600"
                                    >
                                        New Proforma Invoice
                                    </button>
                                )}
                            </div>}
                            {selectedCompany &&
                                <div className='mt-[10%]'>
                                    <p>Banner:</p>
                                    <div className="w-full h-16 bg-gray-200 flex items-center justify-center">

                                        <img
                                            src={`${selectedCompany.banner}?height=64&width=600`}
                                            alt="Company Banner"
                                            // width={500}
                                            height={64}
                                            className="object-fit-cover w-full h-[70px]"
                                            style={{ objectFit: 'cover' }}
                                        />

                                    </div>
                                </div>
                            }


                        </div>
                    )
                }




                <ToastContainer />
            </div>
        </div>
    );
};

export default Dashboard;










// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';
// // import jwtDecode from 'jwt-decode';
// import axios from 'axios';
// import {
//     FaBars, FaTimes, FaUserPlus, FaBuilding, FaFileInvoiceDollar, FaBox, FaUser, FaImage, FaUsers, FaSignOutAlt,
// } from 'react-icons/fa';
// import AddCompany from './Pages/AddCompany';
// import CreateQuatation from './CreateQuatation';
// import AddProduct from './Pages/AddProduct';
// import CreateUser from './Pages/CreateUser';
// import QuotationList from './QuatationList';
// import AddClient from './AddClient';
// import UploadImage from './UploadImage';
// import AllClients from './AllClients';
// // import './Dashboard.css';

// const hasPermission = (permissions, permissionName) => {
//     return permissions.some(permission => permission.name === permissionName);
// };

// const Dashboard = () => {
//     const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//     const [activeComponent, setActiveComponent] = useState(null);
//     const [userName, setUserName] = useState("");
//     const [userRole, setUserRole] = useState("");
//     const [userPermissions, setUserPermissions] = useState([]);
//     const [companies, setCompanies] = useState([]);
//     const [selectedCompany, setSelectedCompany] = useState(null);
//     const [canCreateUser, setCanCreateUser] = useState(false);
//     const [canAddCompany, setCanAddCompany] = useState(false);
//     const [canAddProduct, setCanAddProduct] = useState(false);
//     const [canCreateQuatation, setCanCreateQuatation] = useState(false);
//     const [canAddClient, setCanAddClient] = useState(false);

//     const navigate = useNavigate();

//     const toggleSidebar = () => {
//         setIsSidebarOpen(!isSidebarOpen);
//     };

//     const toggleComponent = (componentName) => {
//         if (activeComponent === componentName) {
//             setActiveComponent(null);
//         } else {
//             setActiveComponent(componentName);
//         }
//     };

//     const onLogout = () => {
//         localStorage.clear();
//         navigate('/');
//     };

//     useEffect(() => {
//         const getUserDetails = async () => {
//             const token = localStorage.getItem('token');
//             if (!token) {
//                 navigate('/login');
//                 return;
//             }

//             try {
//                 const decodedToken = jwtDecode(token);
//                 const response = await axios.get(`https://emkayindia.in/api/v1/user/get/${decodedToken.id}`, {
//                     headers: { Authorization: `Bearer ${token}` },
//                 });

//                 setUserName(response.data.name);
//                 setUserRole(response.data.role);
//                 setUserPermissions(response.data.permission);

//                 setCanCreateUser(hasPermission(response.data.permission, "createUser"));
//                 setCanAddCompany(hasPermission(response.data.permission, "addCompany"));
//                 setCanAddProduct(hasPermission(response.data.permission, "addProduct"));
//                 setCanCreateQuatation(hasPermission(response.data.permission, "quotation"));
//                 setCanAddClient(hasPermission(response.data.permission, "addClient"));
//             } catch (err) {
//                 navigate('/login');
//             }
//         };

//         getUserDetails();
//     }, [navigate]);

//     return (
//         <div className="min-h-screen bg-gray-100">
//             {/* Header */}
//             <header className="bg-gray-800 text-white py-4 px-6 flex justify-between items-center fixed w-full">
//                 <div className="flex items-center">
//                     <button onClick={toggleSidebar} className="mr-3 text-xl">
//                         {isSidebarOpen ? <FaTimes /> : <FaBars />}
//                     </button>
//                     <h1>Welcome, {userName}</h1>
//                 </div>
//                 {selectedCompany && <p>Selected Company: {selectedCompany.companyName}</p>}
//                 <button onClick={onLogout} className="text-xl">
//                     <FaSignOutAlt />
//                 </button>
//             </header>

//             {/* Sidebar */}
//             <aside className={`fixed top-0 left-0 h-full bg-gray-900 text-white transition-transform transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} w-64`}>
//                 <nav className="flex flex-col p-4 mt-16">
//                     {(userRole === 'SUPERADMIN' || canCreateUser) && (
//                         <button onClick={() => toggleComponent('CreateUser')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center">
//                             <FaUserPlus className="mr-2" /> Create User
//                         </button>
//                     )}
//                     {(userRole === 'SUPERADMIN' || canAddCompany) && (
//                         <button onClick={() => toggleComponent('AddCompany')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center">
//                             <FaBuilding className="mr-2" /> Add Company
//                         </button>
//                     )}
//                     {(userRole === 'SUPERADMIN' || canCreateQuatation) && (
//                         <button onClick={() => toggleComponent('CreateQuatation')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center">
//                             <FaFileInvoiceDollar className="mr-2" /> Create Quatation
//                         </button>
//                     )}
//                     {(userRole === 'SUPERADMIN' || canAddProduct) && (
//                         <button onClick={() => toggleComponent('AddProduct')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center">
//                             <FaBox className="mr-2" /> Add Product
//                         </button>
//                     )}
//                     {(userRole === 'SUPERADMIN' || canAddClient) && (
//                         <button onClick={() => toggleComponent('AddClient')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center">
//                             <FaUser className="mr-2" /> Add Client
//                         </button>
//                     )}
//                     <button onClick={() => toggleComponent('UploadImage')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center">
//                         <FaImage className="mr-2" /> Upload Image
//                     </button>
//                     <button onClick={() => toggleComponent('AllClients')} className="py-2 px-4 mb-2 hover:bg-gray-700 flex items-center">
//                         <FaUsers className="mr-2" /> All Clients
//                     </button>
//                 </nav>
//             </aside>

//             {/* Main Content */}
//             <main className="ml-64 p-4 mt-16">
//                 {activeComponent === 'CreateUser' && <CreateUser />}
//                 {activeComponent === 'AddCompany' && <AddCompany />}
//                 {activeComponent === 'CreateQuatation' && <CreateQuatation selectedCompany={selectedCompany} />}
//                 {activeComponent === 'AddProduct' && <AddProduct />}
//                 {activeComponent === 'AddClient' && <AddClient selectedCompany={selectedCompany} />}
//                 {activeComponent === 'UploadImage' && <UploadImage />}
//                 {activeComponent === 'AllClients' && <AllClients />}
//             </main>

//             <ToastContainer />
//         </div>
//     );
// };

// export default Dashboard;
