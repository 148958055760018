// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Select from 'react-select';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const BulkProductUpload = ({ toggleComponent }) => {
//     const [file, setFile] = useState(null);
//     const [selectedCompanies, setSelectedCompanies] = useState([]);
//     const [companyOptions, setCompanyOptions] = useState([]);

//     useEffect(() => {
//         const fetchCompanies = async () => {
//             try {
//                 const token = localStorage.getItem('token');
//                 const response = await axios.get('https://emkayindia.in/api/v1/company/all', {
//                     headers: { 'Authorization': `Bearer ${token}` }
//                 });

//                 const options = response.data.map(company => ({
//                     value: company.id,
//                     label: company.companyName
//                 }));
//                 setCompanyOptions(options);
//             } catch (error) {
//                 console.error('Error fetching companies:', error);
//                 toast.error('Error loading company list');
//             }
//         };

//         fetchCompanies();
//     }, []);

//     const handleFileChange = (e) => {
//         setFile(e.target.files[0]);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (!file) {
//             toast.error('Please select a file');
//             return;
//         }
//         if (selectedCompanies.length === 0) {
//             toast.error('Please select at least one company');
//             return;
//         }

//         const formData = new FormData();
//         formData.append('file', file);
//         formData.append('companyIds', JSON.stringify(selectedCompanies.map(company => company.value)));

//         try {
//             const token = localStorage.getItem('token');
//             await axios.post('https://emkayindia.in/api/v1/product/createmany', formData, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });
//             toast.success('Products uploaded successfully!');
//             // toggleComponent("DashBoard");
//         } catch (error) {
//             toast.error(`Error uploading products: ${error.response?.data?.message || 'Unknown error'}`);
//         }
//     };

//     return (
//         <form onSubmit={handleSubmit} className="p-6 bg-white rounded shadow-md w-[40%] mx-auto mt-20">
//             <h2 className="text-xl font-semibold mb-4">Bulk Product Upload</h2>

//             <div className="mb-4">
//                 <label className="block text-gray-700 mb-2">Select File:</label>
//                 <input
//                     type="file"
//                     onChange={handleFileChange}
//                     className="block w-full text-sm text-gray-600 bg-gray-200 rounded border border-gray-300 cursor-pointer focus:outline-none"
//                 />
//             </div>

//             <div className="mb-4">
//                 <label className="block text-gray-700 mb-2">Select Companies:</label>
//                 <Select
//                     options={companyOptions}
//                     isMulti
//                     value={selectedCompanies}
//                     onChange={setSelectedCompanies}
//                     className="basic-multi-select"
//                     classNamePrefix="select"
//                     placeholder="Select companies..."
//                 />
//             </div>

//             <button
//                 type="submit"
//                 className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
//             >
//                 Upload Products
//             </button>

//             <ToastContainer />
//         </form>
//     );
// };

// export default BulkProductUpload;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaDownload } from 'react-icons/fa';

const BulkProductUpload = ({ toggleComponent }) => {
    const [file, setFile] = useState(null);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const fetchCompanies = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://emkayindia.in/api/v1/company/all', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                const options = response.data.map(company => ({
                    value: company.id,
                    label: company.companyName
                }));
                setCompanyOptions(options);
            } catch (error) {
                console.error('Error fetching companies:', error);
                toast.error('Error loading company list');
            }finally{
                setIsLoading(false);
            }
        };

        fetchCompanies();
    }, []);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            toast.error('Please select a file');
            return;
        }
        if (selectedCompanies.length === 0) {
            toast.error('Please select at least one company');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('companyIds', JSON.stringify(selectedCompanies.map(company => company.value)));

        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            await axios.post('https://emkayindia.in/api/v1/product/createmany', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success('Products uploaded successfully!');
            // toggleComponent("DashBoard");
        } catch (error) {
            toast.error(`Error uploading products: ${error.response?.data?.message || 'Unknown error'}`);
        }finally{
            setIsLoading(false);
        }
    };

    const handleDownloadProducts = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            const response = await axios.get('https://emkayindia.in/api/v1/product/download', {
                headers: { 'Authorization': `Bearer ${token}` },
                responseType: 'blob', // Important for file download
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'products_data.xlsx'); // Set the filename
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            toast.success('Product data downloaded successfully!');
        } catch (error) {
            console.error('Error downloading product data:', error);
            toast.error('Error downloading product data');
        }
        finally{
            setIsLoading(false);
        }
    };

//     return (
//         <form onSubmit={handleSubmit} className="p-6 bg-white rounded shadow-md w-[40%] mx-auto mt-20">
//             <h2 className="text-xl font-semibold mb-4">Bulk Product Upload</h2>

//             <div className="mb-4">
//                 <label className="block text-gray-700 mb-2">Select File:</label>
//                 <input
//                     type="file"
//                     onChange={handleFileChange}
//                     className="block w-full text-sm text-gray-600 bg-gray-200 rounded border border-gray-300 cursor-pointer focus:outline-none"
//                 />
//             </div>

//             <div className="mb-4">
//                 <label className="block text-gray-700 mb-2">Select Companies:</label>
//                 <Select
//                     options={companyOptions}
//                     isMulti
//                     value={selectedCompanies}
//                     onChange={setSelectedCompanies}
//                     className="basic-multi-select"
//                     classNamePrefix="select"
//                     placeholder="Select companies..."
//                 />
//             </div>

//             <div className="flex justify-between">
//                     <button
//                         type="submit"
//                         className="bg-[#C6C5B9] text-[#848484] py-2 px-4 mr-2"
//                     >
//                         Upload Products
//                     </button>

//                     <button
//                         type="button"
//                         onClick={handleDownloadProducts}
//                         className="flex items-center bg-white text-[#848484] py-2 px-4 border-2  hover:border-3 transition duration-300"
//                     >
//                         <FaDownload className="mr-2" /> All Products
//                     </button>
//                 </div>

//             <ToastContainer />
//         </form>
//     );
// };

// export default BulkProductUpload;

return (
    <div className="relative h-[100%]">
        {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-10 backdrop-blur-sm z-10">
                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            </div>
        )}

        <form
            onSubmit={handleSubmit}
            className={`p-6 bg-white rounded shadow-md w-[40%] mx-auto mt-8 ${
                isLoading ? 'pointer-events-none' : ''
            }`}
        >
            <h2 className="text-2xl font-semibold text-[#848484] mb-4">Bulk Product Upload</h2>

            <div className="mb-4">
                <label className="block text-[#848484] mb-2">Select File:</label>
                <input
                    type="file"
                    onChange={handleFileChange}
                    className="block w-full text-sm text-gray-600 bg-gray-200 rounded border border-gray-300 cursor-pointer focus:outline-none"
                />
            </div>

            <div className="mb-4">
                <label className="block text-[#848484] mb-2">Select Companies:</label>
                <Select
                    options={companyOptions}
                    isMulti
                    value={selectedCompanies}
                    onChange={setSelectedCompanies}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select companies..."
                />
            </div>
            <div className="flex justify-between">
                <button
                    type="submit"
                    className="bg-[#C6C5B9] text-[#848484] py-2 px-4 mr-2"
                >
                    Upload Products
                </button>

                <button
                    type="button"
                    onClick={handleDownloadProducts}
                    className="flex items-center bg-white text-[#848484] py-2 px-4 border-2  hover:border-3 transition duration-300"
                >
                    <FaDownload className="mr-2" />Download All Products
                </button>
            </div>

            <ToastContainer />
        </form>
    </div>
);
};

export default BulkProductUpload;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Select from 'react-select';
// import { ToastContainer, toast } from 'react-toastify';
// import * as XLSX from 'xlsx';
// import 'react-toastify/dist/ReactToastify.css';
// import { FaDownload } from 'react-icons/fa';

// const BulkProductUpload = ({ toggleComponent }) => {
//     const [file, setFile] = useState(null);
//     const [selectedCompanies, setSelectedCompanies] = useState([]);
//     const [companyOptions, setCompanyOptions] = useState([]);

//     useEffect(() => {
//         const fetchCompanies = async () => {
//             try {
//                 const token = localStorage.getItem('token');
//                 const response = await axios.get('https://emkayindia.in/api/v1/company/all', {
//                     headers: { Authorization: `Bearer ${token}` },
//                 });

//                 const options = response.data.map((company) => ({
//                     value: company.id,
//                     label: company.companyName,
//                 }));
//                 setCompanyOptions(options);
//             } catch (error) {
//                 console.error('Error fetching companies:', error);
//                 toast.error('Error loading company list');
//             }
//         };

//         fetchCompanies();
//     }, []);

//     const handleFileChange = (e) => {
//         setFile(e.target.files[0]);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (!file) {
//             toast.error('Please select a file');
//             return;
//         }
//         if (selectedCompanies.length === 0) {
//             toast.error('Please select at least one company');
//             return;
//         }

//         const formData = new FormData();
//         formData.append('file', file);
//         formData.append(
//             'companyIds',
//             JSON.stringify(selectedCompanies.map((company) => company.value))
//         );

//         try {
//             const token = localStorage.getItem('token');
//             await axios.post('https://emkayindia.in/api/v1/product/createmany', formData, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });
//             toast.success('Products uploaded successfully!');
//             // toggleComponent("DashBoard");
//         } catch (error) {
//             toast.error(`Error uploading products: ${error.response?.data?.message || 'Unknown error'}`);
//         }
//     };

//     const handleDownloadProducts = async () => {
//         try {
//             const token = localStorage.getItem('token');
//             const response = await axios.get('https://emkayindia.in/api/v1/product/all', {
//                 headers: { Authorization: `Bearer ${token}` },
//             });

//             // Convert the data into a worksheet
//             const worksheet = XLSX.utils.json_to_sheet(response.data);
//             const workbook = XLSX.utils.book_new();
//             XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');

//             // Write the Excel file
//             XLSX.writeFile(workbook, 'products_data.xlsx');

//             toast.success('Product data downloaded successfully!');
//         } catch (error) {
//             console.error('Error downloading product data:', error);
//             toast.error('Error downloading product data');
//         }
//     };

//     return (
//         <form onSubmit={handleSubmit} className="p-6 bg-white rounded shadow-md w-[40%] mx-auto mt-20">
//             <h2 className="text-xl font-semibold mb-4">Bulk Product Upload</h2>

//             <div className="mb-4">
//                 <label className="block text-gray-700 mb-2">Select File:</label>
//                 <input
//                     type="file"
//                     onChange={handleFileChange}
//                     className="block w-full text-sm text-gray-600 bg-gray-200 rounded border border-gray-300 cursor-pointer focus:outline-none"
//                 />
//             </div>

//             <div className="mb-4">
//                 <label className="block text-gray-700 mb-2">Select Companies:</label>
//                 <Select
//                     options={companyOptions}
//                     isMulti
//                     value={selectedCompanies}
//                     onChange={setSelectedCompanies}
//                     className="basic-multi-select"
//                     classNamePrefix="select"
//                     placeholder="Select companies..."
//                 />
//             </div>
//             <div className='flex justify-between'>
//                 <button
//                     type="submit"
//                     className="bg-[#C6C5B9] text-[#848484] py-2 px-4  mr-2"
//                 >
//                     Upload Products
//                 </button>

//                 <button
//                     type="button"
//                     onClick={handleDownloadProducts}
//                     className="flex items-center bg-white text-[#848484] py-2 px-4 border-2 rounded hover:border-3 transition duration-300"
//                 >
//                     <FaDownload className="mr-2" />  All Products
//                 </button>
//             </div>

//             <ToastContainer />
//         </form>
//     );
// };

// export default BulkProductUpload;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Select from 'react-select';
// import { ToastContainer, toast } from 'react-toastify';
// import * as XLSX from 'xlsx';
// import { FaDownload } from 'react-icons/fa';
// import 'react-toastify/dist/ReactToastify.css';

// const BulkProductUpload = ({ toggleComponent }) => {
//     const [file, setFile] = useState(null);
//     const [selectedCompanies, setSelectedCompanies] = useState([]);
//     const [companyOptions, setCompanyOptions] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);

//     useEffect(() => {
//         const fetchCompanies = async () => {
//             setIsLoading(true);
//             try {
//                 const token = localStorage.getItem('token');
//                 const response = await axios.get('https://emkayindia.in/api/v1/company/all', {
//                     headers: { Authorization: `Bearer ${token}` },
//                 });

//                 const options = response.data.map((company) => ({
//                     value: company.id,
//                     label: company.companyName,
//                 }));
//                 setCompanyOptions(options);
//             } catch (error) {
//                 console.error('Error fetching companies:', error);
//                 toast.error('Error loading company list');
//             } finally {
//                 setIsLoading(false);
//             }
//         };

//         fetchCompanies();
//     }, []);

//     const handleFileChange = (e) => {
//         setFile(e.target.files[0]);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (!file) {
//             toast.error('Please select a file');
//             return;
//         }
//         if (selectedCompanies.length === 0) {
//             toast.error('Please select at least one company');
//             return;
//         }

//         const formData = new FormData();
//         formData.append('file', file);
//         formData.append(
//             'companyIds',
//             JSON.stringify(selectedCompanies.map((company) => company.value))
//         );

//         try {
//             setIsLoading(true);
//             const token = localStorage.getItem('token');
//             await axios.post('https://emkayindia.in/api/v1/product/createmany', formData, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });
//             toast.success('Products uploaded successfully!');
//             // toggleComponent("DashBoard");
//         } catch (error) {
//             toast.error(`Error uploading products: ${error.response?.data?.message || 'Unknown error'}`);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const handleDownloadProducts = async () => {
//         try {
//             setIsLoading(true);
//             const token = localStorage.getItem('token');
//             const response = await axios.get('https://emkayindia.in/api/v1/product/download', {
//                 headers: { Authorization: `Bearer ${token}` },
//             });

//             // Convert the data into a worksheet
//             const worksheet = XLSX.utils.json_to_sheet(response.data);
//             const workbook = XLSX.utils.book_new();
//             XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');

//             // Write the Excel file
//             XLSX.writeFile(workbook, 'products_data.xlsx');

//             toast.success('Product data downloaded successfully!');
//         } catch (error) {
//             console.error('Error downloading product data:', error);
//             toast.error('Error downloading product data');
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     return (
//         <div className="relative h-[100%]">
//             {isLoading && (
//                 <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-10 backdrop-blur-sm z-10">
//                     <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
//                 </div>
//             )}

//             <form
//                 onSubmit={handleSubmit}
//                 className={`p-6 bg-white rounded shadow-md w-[40%] mx-auto mt-8 ${
//                     isLoading ? 'pointer-events-none' : ''
//                 }`}
//             >
//                 <h2 className="text-2xl font-semibold text-[#848484] mb-4">Bulk Product Upload</h2>

//                 <div className="mb-4">
//                     <label className="block text-[#848484] mb-2">Select File:</label>
//                     <input
//                         type="file"
//                         onChange={handleFileChange}
//                         className="block w-full text-sm text-gray-600 bg-gray-200 rounded border border-gray-300 cursor-pointer focus:outline-none"
//                     />
//                 </div>

//                 <div className="mb-4">
//                     <label className="block text-[#848484] mb-2">Select Companies:</label>
//                     <Select
//                         options={companyOptions}
//                         isMulti
//                         value={selectedCompanies}
//                         onChange={setSelectedCompanies}
//                         className="basic-multi-select"
//                         classNamePrefix="select"
//                         placeholder="Select companies..."
//                     />
//                 </div>
//                 <div className="flex justify-between">
//                     <button
//                         type="submit"
//                         className="bg-[#C6C5B9] text-[#848484] py-2 px-4 mr-2"
//                     >
//                         Upload Products
//                     </button>

//                     <button
//                         type="button"
//                         onClick={handleDownloadProducts}
//                         className="flex items-center bg-white text-[#848484] py-2 px-4 border-2  hover:border-3 transition duration-300"
//                     >
//                         <FaDownload className="mr-2" /> All Products
//                     </button>
//                 </div>

//                 <ToastContainer />
//             </form>
//         </div>
//     );
// };

// export default BulkProductUpload;
