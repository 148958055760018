import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/CreateUser.css'; // Import the CSS file
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CreateUser = ({ toggleComponent }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [lastName,setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('USER');
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
  const [isPermissionDropdownOpen, setIsPermissionDropdownOpen] = useState(false);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [pincode, setPincode] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine3, setAddressLine3] = useState('');
  const [state, setState] = useState('');
  const permissionDropdownRef = useRef(null);
  const companyDropdownRef = useRef(null);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  useEffect(() => {
    const token = localStorage.getItem('token');

    axios.get('https://emkayindia.in/api/v1/permission/all', {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => setPermissions(response.data))
      .catch(error => console.error('Error fetching permissions:', error));

    axios.get('https://emkayindia.in/api/v1/company/all', {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => setCompanies(response.data))
      .catch(error => console.error('Error fetching companies:', error));

      
  }, []);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close permission dropdown if clicked outside of it
      if (permissionDropdownRef.current && !permissionDropdownRef.current.contains(event.target)) {
        setIsPermissionDropdownOpen(false);
      }
      
      // Close company dropdown if clicked outside of it
      if (companyDropdownRef.current && !companyDropdownRef.current.contains(event.target)) {
        setIsCompanyDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePermissionChange = (permissionId) => {
    setSelectedPermissions(prevSelected => {
      if (prevSelected.includes(permissionId)) {
        return prevSelected.filter(id => id !== permissionId);
      } else {
        return [...prevSelected, permissionId];
      }
    });
  };

  const handleCompanyChange = (companyId) => {
    setSelectedCompanies(prevSelected => {
      if (prevSelected.includes(companyId)) {
        return prevSelected.filter(id => id !== companyId);
      } else {
        return [...prevSelected, companyId];
      }
    });
  };

  const toggleCompanyDropdown = () => {
    setIsCompanyDropdownOpen(!isCompanyDropdownOpen);
  };

  const togglePermissionDropdown = () => {
    setIsPermissionDropdownOpen(!isPermissionDropdownOpen);
  };

  const handleSubmit = (e) => {
    const token = localStorage.getItem('token');
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    const userData = {
      email,
      name:name+" "+lastName,
      password,
      role,
      permission: selectedPermissions,
      companies: selectedCompanies,
      address: addressLine1 + '0/' + addressLine2 + '0/' + addressLine3,
      city,
      phone,
      pincode,
      state
    };

    axios.post('https://emkayindia.in/api/v1/user/create', userData, {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => {
        toast.success('User created successfully!');
        toggleComponent("CreateUser");
        console.log('User created successfully:', response.data);
      })
      .catch(error => {
        toast.error(`Error creating user: ${error.response?.data?.message || 'Unknown error'}`);
        console.error('Error creating user:', error);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="create-user-form text-[#848484] ml-auto bg-[#f9f9f9]  w-[100%] font-sans">
      <div className='create-user-top mt-4'>
        <p className='text-[#848484] text-3xl font-bold'>Create User</p>
        {/* <button type="submit" className="submit-button">Save User</button> */}
      </div>

      <div className='ml-4 text-xl w-[90%] border-b-2'>
        <h3>Personal Details</h3>
      </div>
      <div className="section personal-details flex flex-col w-[90%] font-normal" >
        <div className='flex w-[100%] justify-between  mt-2 '>
          <div className='flex flex-col w-[75%] mr-8'>
            <label className='text-lg  font-normal'>First Name:</label>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} required className="input-field w-[70%]" />
          </div>
          <div className='flex flex-col w-[75%] mr-4 font-normal'>
            <label className='text-lg  font-normal'>Last Name:</label>
            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required className="input-field w-[70%] " /> {/*TODO*/}
          </div>
        </div>

        <div className="w-[100%] flex justify-between  pr-4 mt-4">
          <div className='flex flex-col w-[75%] mr-8'>
            <label className="text-lg  ">Address line 1:</label>
            <input
              type="text"
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
              required
              className="input-field border border-gray-300   "
            />
          </div>
          <div className='flex flex-col w-[75%]'>
            <label className="text-lg  ">Address line 2:</label>
            <input
              type="text"
              value={addressLine2}
              onChange={(e) => setAddressLine2(e.target.value)}
              className="input-field border border-gray-300    "
            />
          </div>
        </div>
        <div className="input-sec flex mt-4 justify-between w-[100%]">
          <div className='input-sec w-[30%]'>
            <label  className="text-lg  ">City:</label>
            <input type="text" value={city} onChange={(e) => setCity(e.target.value)} required className="input-field w-[100%]" />
          </div>
          <div className='input-sec w-[30%]'>
            <label  className="text-lg">Pincode:</label>
            <input type="text" value={pincode} onChange={(e) => setPincode(e.target.value)} required className="input-field w-[100%]" />
          </div>
          <div className='input-sec w-[30%]'>
            <label className="text-lg">State:</label>
            <input type="text" value={state} className="input-field w-[95%] "
              onChange={(e) => setState(e.target.value)} />
          </div>
        </div>
        <div className='w-[100%] flex justify-between pr-4 mt-4'>
          <div className='input-sec w-[50%] mr-8'>
            <label className="text-lg">Email:</label>
            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} required className="input-field w-[100%]" />
          </div>
          <div className='input-sec w-[50%]'>
            <label className="text-lg">Phone:</label>
            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} required className="input-field w-[100%]" />
          </div>
        </div>

      </div>
      <div className='ml-4 font-normal text-xl border-b-2 mt-4 w-[90%]'>
        <h3>Login Details </h3>
      </div>
      <div className='section personal-details flex  w-[90%] '>
        <div className='w-[30%] flex flex-col'>
          <label className="text-lg">User Name:</label>
          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} required className="input-field w-[100%]" />
        </div>
        <div className="flex flex-col w-[30%] ">
          <label className="text-lg">Password:</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="input-field  border border-gray-300 p-4 w-[100%]" // Tailwind classes for styling
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={toggleShowPassword}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer "
            />
          </div>
        </div>
        <div className='w-[30%] mr-4'>
          <label className="text-lg">Confirm Password:</label>
          <div className="relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="input-field  border border-gray-300 p-4 w-[100%]" // Tailwind classes for styling
            />
            <FontAwesomeIcon
              icon={showConfirmPassword ? faEyeSlash : faEye}
              onClick={toggleShowConfirmPassword}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer "
            />
          </div>
        </div>
      </div>

      <div className='ml-4 font-normal text-xl border-b-2 mt-4 w-[90%] '>
        <h3>Work Details </h3>
      </div>
      <div className="section work-details section flex flex-col w-[90%] ">
        <div className="dropdown-section pl-4 pr-8">
          <div className="role-section w-[30%] mt-4">
            <div className="role-dropdown flex flex-col justify-start">
              <div htmlFor="role" className='w-[100%] text-lg'>Role:</div>
              <select id="role" value={role} onChange={(e) => setRole(e.target.value)} className="dropdown-select text-lg">
                <option value="USER">USER</option>
                <option value="ADMIN">ADMIN</option>
                <option value="SUPERADMIN">SUPERADMIN</option>
              </select>
            </div>
          </div>
          <div className="relative  w-[30%]"  ref={permissionDropdownRef}>
            <div className="permission-sec flex flex-col w-[100]">
              <div className="w-[100%] text-lg ">Permissions:</div>
              <button
                type="button"
                className="dropdown-button px-4 py-2 bg-gray-100 border text-start"
                onClick={togglePermissionDropdown}
              >
                Select Permissions {selectedPermissions.length > 0 && `(${selectedPermissions.length})`}
              </button>

              {isPermissionDropdownOpen && (
                <div className="absolute bottom-full mb-2 left-0 z-10 bg-white border border-gray-300 shadow-lg rounded-md p-2 max-h-48 overflow-y-auto w-full">
                  {permissions.map(permission => (
                    <div key={permission.id} className="flex items-center">
                      <input
                        type="checkbox"
                        value={permission.id}
                        checked={selectedPermissions.includes(permission.id)}
                        onChange={() => handlePermissionChange(permission.id)}
                        className="mr-2"
                      />
                      <label className="text-gray-700">{permission.name.charAt(0).toUpperCase() + permission.name.slice(1)}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="relative   w-[30%] " ref={companyDropdownRef}>
            <div className=' justify-center align-middle permission-sec flex flex-col'>
              <div className='w-[100%] text-lg'>Companies:</div>
              <button
                type="button"
                className="dropdown-button  px-4 py-2 bg-gray-100 border text-start"
                onClick={toggleCompanyDropdown}
              >
                Select Companies {selectedCompanies.length > 0 && `(${selectedCompanies.length})`}
              </button>

              {isCompanyDropdownOpen && (
                <div className="absolute bottom-full mb-2 left-0 z-10 bg-white border border-gray-300 shadow-lg rounded-md p-2 max-h-48 overflow-y-auto w-full">
                  {companies.map(company => (
                    <div key={company.id} className="flex items-center">
                      <input
                        type="checkbox"
                        value={company.id}
                        checked={selectedCompanies.includes(company.id)}
                        onChange={() => handleCompanyChange(company.id)}
                        className="mr-2"
                      />
                      <label className="text-gray-700">{company.companyName}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="selected-values w-[100%] h-[100%] mt-6 flex-col">
            <div className="selected-permissions border-gray-300  pl-6 flex ">
              <p className='mr-4 text-lg'>Selected Permissions:</p>
              <ul>
                {selectedPermissions.map(permissionId => {
                  const permission = permissions.find(p => p.id === permissionId);
                  return <li key={permissionId}>{permission.name}</li>;
                })}
              </ul>
            </div>
            <div className="selected-companies border-gray-300 p-1 mt-4">
              <p className='mr-3 text-lg'>Selected Companies:</p>
              <ul>
                {selectedCompanies.map(companyId => {
                  const company = companies.find(c => c.id === companyId);
                  return <li key={companyId}>{company.companyName}</li>;
                })}
              </ul>
            </div>
          {/* )} */}
        </div>
        <div className='flex justify-center align-middle '>
          <button type="submit" className="submit-button bg-[#C6C5B9] text-xl font-semibold mt-8 mb-8 w-[20%]">Save User</button>
        </div>


      </div>

      {/* <button type="submit" className="submit-button">Create User</button> */}
      <ToastContainer />
    </form>
  );
};

export default CreateUser;

