import React, { useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import '../css/AddCompany.css'
import { toast } from 'react-toastify';

const AddCompany = ({ toggleComponent }) => {
  const [companyName, setCompanyName] = useState('');
  const [gstNo, setGstNo] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [logo, setLogo] = useState('');
  const [banner, setBanner] = useState('');
  const [terms, setTerms] = useState([{ id: 1, text: '' }]); // Array for terms
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [bannerUrl, setBannerUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false); // To track if an image was successfully uploaded
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine3, setAddressLine3] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [state, setState] = useState('');
  const [loading, setLoading] = useState(false);
  const [accountNo, setAccountNo] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [bankName, setBankName] = useState('');
  const [branch, setBranch] = useState('');
  const [accountName, setAccountName] = useState('');
  const [banners, setBanners] = useState(Array(5).fill(null));


  const uploadImage = async (file) => {
    setIsUploading(true); // Start loading state
    const formData = new FormData();
    formData.append('image', file);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post('https://emkayindia.in/api/v1/image/upload', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      return response.data.url; // Return the uploaded image URL
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image');
      return null;
    } finally {
      setIsUploading(false); // Stop loading state
    }
  };

  const handleBannerChange = async (file, index) => {
    const uploadedUrl = await uploadImage(file); // Upload image and get the URL
    if (uploadedUrl) {
      const updatedBanners = [...banners];
      updatedBanners[index] = uploadedUrl; // Store the returned URL in the corresponding position
      setBanners(updatedBanners);
    }
  };

  const handleImageChange = async (file) => {
    const uploadedUrl = await uploadImage(file);
    if (uploadedUrl) {
      setBannerUrl(uploadedUrl); // Set the image URL
      setIsUploaded(true); // Mark the image as successfully uploaded
    }
  };

  const handleImageChangeLogo = async (file) => {
    const uploadedUrl = await uploadImage(file);
    if (uploadedUrl) {
      setLogo(uploadedUrl); // Set the image URL
      setIsUploaded(true); // Mark the image as successfully uploaded
    }
  };

  const handleTermChange = (index, event) => {
    const updatedTerms = [...terms];
    updatedTerms[index].text = event.target.value;
    setTerms(updatedTerms);
  };

  const addTerm = () => {
    setTerms([...terms, { id: terms.length + 1, text: '' }]);
  };

  const removeTerm = (index) => {
    const updatedTerms = terms.filter((_, i) => i !== index);
    setTerms(updatedTerms);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const token = localStorage.getItem('token');
  //     const decodedToken = jwtDecode(token);

  //     const companyData = {
  //       companyName,
  //       gstNo,
  //       email,
  //       phone,
  //       address,
  //       logo,
  //       banner,
  //       createdBy: decodedToken.id,
  //       terms: {
  //         termsAndConditions: terms.map(term => term.text),
  //       },
  //     };

  //     const response = await axios.post('https://emkayindia.in/api/v1/company/create', companyData, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //       },
  //     });

  //     setSuccess('Company created successfully');
  //     console.log('Success:', response.data);

  //     setCompanyName('');
  //     setGstNo('');
  //     setEmail('');
  //     setPhone('');
  //     setAddress('');
  //     setLogo('');
  //     setBanner('');
  //     setTerms([{ id: 1, text: '' }]);
  //   } catch (err) {
  //     setError('Failed to create company');
  //     console.error('Error:', err);
  //   }
  // };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   try {
  //     // Get token from localStorage
  //     const token = localStorage.getItem('token');
  //     const decodedToken = jwtDecode(token);

  //     // Prepare company data with terms
  //     const companyData = {
  //       companyName,
  //       gstNo,
  //       email,
  //       phone,
  //       address: addressLine1 + '0/' + addressLine2,
  //       logo: logo,
  //       banner: bannerUrl,
  //       createdBy: decodedToken.id,
  //       terms: {
  //         termsAndConditions: terms.map(term => term.text), // Array of terms
  //       },
  //       city,
  //       state,
  //       pincode,
  //       bankName,
  //       bankBranch: branch,
  //       bankIFSC: ifscCode,
  //       bankAccount: accountNo,
  //       accountName
  //     };

  //     console.log("Sending company data:", companyData);

  //     // Send POST request to create company
  //     const response = await axios.post(
  //       'https://emkayindia.in/api/v1/company/create',
  //       companyData,
  //       {
  //         headers: {
  //           'Authorization': `Bearer ${token}`,
  //           // 'Content-Type': 'application/json',
  //         },
  //       }

  //     );
  //     setLoading(false);

  //     // Success handling
  //     setSuccess('Company created successfully');
  //     console.log('Success:', response.data);
  //     toast.success('Comapany Added');
  //     toggleComponent('AddCompany');

  //     // Clear form fields after success
  //     setCompanyName('');
  //     setGstNo('');
  //     setEmail('');
  //     setPhone('');
  //     setAddress('');
  //     setLogo('');
  //     setBanner('');
  //     setTerms([{ id: 1, text: '' }]);
  //   } catch (err) {
  //     // Handle different types of errors and log them
  //     if (err.response) {
  //       console.error('Response error:', err.response.data);
  //       setError(`Error: ${err.response.data.message || 'Failed to create company'}`);
  //     } else if (err.request) {
  //       console.error('Request error:', err.request);
  //       setError('Error: No response from the server.');
  //     } else {
  //       console.error('Error:', err.message);
  //       setError('Error: Failed to create company.');
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Get token from localStorage
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);

      // Use the first banner URL for the banner field
      const firstBanner = banners[0] || ""; // Assuming 'banners' holds URLs of uploaded banners
      console.log(banners);

      // Prepare company data with terms
      const companyData = {
        companyName,
        gstNo,
        email,
        phone,
        address: `${addressLine1}0/${addressLine2}`,
        logo: logo,
        banner: firstBanner, // Use the first banner URL
        createdBy: decodedToken.id,
        terms: {
          termsAndConditions: terms.map(term => term.text), // Array of terms
        },
        city,
        state,
        pincode,
        bankName,
        bankBranch: branch,
        bankIFSC: ifscCode,
        bankAccount: accountNo,
        accountName,
        banner1: banners[0],
        banner2: banners[1],
        banner3: banners[2],
        banner4: banners[3],
        banner5: banners[4],
      };

      console.log("Sending company data:", companyData);

      // Send POST request to create company
      const response = await axios.post(
        'https://emkayindia.in/api/v1/company/create',
        companyData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);

      // Success handling
      setSuccess('Company created successfully');
      console.log('Success:', response.data);
      toast.success('Company Added');
      toggleComponent('AddCompany');

      // Clear form fields after success
      setCompanyName('');
      setGstNo('');
      setEmail('');
      setPhone('');
      setAddress('');
      setLogo('');
      setBanners([]); // Clear banner URLs
      setTerms([{ id: 1, text: '' }]);
    } catch (err) {
      // Handle different types of errors and log them
      if (err.response) {
        console.error('Response error:', err.response.data);
        setError(`Error: ${err.response.data.message || 'Failed to create company'}`);
      } else if (err.request) {
        console.error('Request error:', err.request);
        setError('Error: No response from the server.');
      } else {
        console.error('Error:', err.message);
        setError('Error: Failed to create company.');
      }
    }
  };

  return (
    <div className="add-company-form font-sans text-[#848484] bg-[##f9f9f9] h-[100vh] overflow-y-scroll">
      <h2 className="text-3xl font-bold mt-2">Add Company</h2>
      {!loading &&
        <form onSubmit={handleSubmit} className='add-company-inner-form w-[90%]'>
          <div>
            <div className='mt-4  text-xl border-b-2 mb-4 border-gray-300'>
              <h3>Company Details</h3>
            </div>
          </div>
          <div className='mt-4 '>
            <div className='flex justify-between text-lg'>
              <div className="flex flex-col justify-start w-[65%]">
                <label className="text-lg">Company Name:</label>
                <input
                  type="text"
                  className="input-field-add-company p-1 "
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>
              <div className="w-[30%]  justify-center">
                <div className=" items-center space-x-2 flex flex-col">
                  <label className="w-[95%] text-lg ">Upload Logo:</label>
                  <input
                    type="file"
                    onChange={(e) => handleImageChangeLogo(e.target.files[0])}
                    required
                    className="block bg-[#C6C5B9] w-[100%] px-2 py-1 border border-gray-300 rounded-md text-sm text-gray-700 cursor-pointer file:bg-gray-200 file:border-none file:py-1 file:px-3 file:mr-2 file:rounded-md file:text-sm file:cursor-pointer file:hover:bg-gray-300"
                  />
                </div>
                {isUploading && <p className="mt-2 text-blue-600">Uploading...</p>}
              </div>
            </div>
            <div className="mb-6 flex w-[100%] mt-4 justify-between">
              <div className="w-[50%] mr-12">
                <label className="text-lg  block">Address Line 1:</label>
                <input
                  type="text"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                  required
                  className="border border-gray-300 p-2 w-[100%] "
                />
              </div>
              <div className='w-[50%]'>
                <label className="text-lg   block">Address Line 2:</label>
                <input
                  type="text"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                  className="border border-gray-300  p-2 w-[100%]"
                />
              </div>
            </div>
            <div className="input-sec flex mt-4 justify-between w-[100%]">
              <div className='w-[30%]'>
                <label className="text-lg">City:</label>
                <input type="text" value={city} onChange={(e) => setCity(e.target.value)} required className="input-field w-[100%]" />
              </div>
              <div className='input-sec w-[30%]'>
                <label className="text-lg">Pincode:</label>
                <input type="text" value={pincode} onChange={(e) => setPincode(e.target.value)} required className="input-field w-[100%]" />
              </div>
              <div className='input-sec w-[30%]'>
                <label className="text-lg">State:</label>
                <input type="text" value={state} className="input-field w-[100%]"
                  onChange={(e) => setState(e.target.value)} />
              </div>
            </div>
            <div className='flex mt-4 justify-between w-[100%]'>
              <div className="form-group-add-company w-[50%] flex flex-col mr-12">
                <p className="text-lg">Email:</p>
                <input
                  type="email"
                  className="input-field-add-company input-field w-[100%]"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group-add-company w-[50%] flex flex-col">
                <p className="text-lg">Phone:</p>
                <input
                  type="text"
                  className="input-field-add-company input-field w-[100%]"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group-add-company w-[100%] flex ">
              <div className='w-[50%] mr-12'>
                <p className="text-lg">GST No:</p>
                <input
                  type="text"
                  className="input-field-add-company input-field w-[100%] "
                  value={gstNo}
                  onChange={(e) => setGstNo(e.target.value)}
                  required
                />
              </div>
              <div className='w-[50%] '>
              </div>

            </div>
            <div className='mt-4  text-xl border-b-2 mb-4 border-gray-300'>
              <h3>Bank Details :</h3>
            </div>
            <div>
              <div className="term-item flex flex-col">
                <div className='flex w-[100%] justify-between'>
                  <div className='flex w-[100%] justify-between mt-4'>
                    <div className='w-[50%] mr-12'>
                      <div className='text-lg'>Account Holder Name</div>
                      <input
                        type="text"
                        className=" input-field-add-company input-field w-[100%] "
                        value={accountName}
                        onChange={(e) => setAccountName(e.target.value)}
                        required
                      />
                    </div>
                    <div className='w-[50%] '>
                      <div className='text-lg'>Account No.</div>
                      <input
                        type="text"
                        className=" input-field-add-company input-field w-[100%] "
                        value={accountNo}
                        onChange={(e) => setAccountNo(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className='flex w-[100%] justify-between mt-4'>
                  <div className='w-[50%] mr-12'>
                    <div className='text-lg'>Bank Name </div>
                    <input
                      type="text"
                      className="input-field-add-company input-field w-[100%] "
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                      required
                    />
                  </div>
                  <div className='w-[50%] '>
                    <div className='text-lg'>Branch</div>
                    <input
                      type="text"
                      className="input-field-add-company input-field w-[100%]"
                      value={branch}
                      onChange={(e) => setBranch(e.target.value)}
                      required
                    />
                  </div>
                </div >
                <div className='flex w-[100%] justify-between mt-4'>
                  <div className='w-[50%] mr-12'>
                    <div className='text-lg'> IFSC Code </div>
                    <input
                      type="text"
                      className="input-field-add-company input-field w-[100%]"
                      value={ifscCode}
                      onChange={(e) => setIfscCode(e.target.value)}
                      required
                    />
                  </div>
                  <div className='w-[50%]'></div>
                </div>
              </div>
            </div>
            <div className='mt-4  text-xl border-b-2 mb-4 border-gray-300'>
              <h3>Add Terms & Conditions :</h3>
            </div>
            <div className="terms-container">
              <div className='terms-sec'>
                {terms.map((term, index) => (
                  <div key={term.id} className="term-item flex flex-col">
                    <label className='text-lg'>Term {index + 1}</label>
                    <div className='input-remove'>
                      <input
                        type="text"
                        className="input-field-terms"
                        value={term.text}
                        onChange={(e) => handleTermChange(index, e)}
                        required
                      />
                      {terms.length > 1 && (
                        <button
                          type="button"
                          className="remove-button"
                          onClick={() => removeTerm(index)}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                ))}
                <div>
                  {
                    terms.length <= 6 && <button type="button" className="add-button mb-1 py-1 px-2 w-[10%]" onClick={addTerm}>
                      Add Term
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='mt-4  text-xl border-b-2  border-gray-300'>
            <h3 >Upload Banners :</h3>
          </div>
          {/* <div className='mt-4'>
            <div className='w-[40%] flex flex-col justify-center'>
              <div className="flex items-center w-[70%]">
                <label className="text-lg">Banner:</label>
                <input
                  type="file"
                  onChange={e => handleImageChange(e.target.files[0])}
                  required // Require at least the first image field
                  className="block bg-[#C6C5B9] w-[70%] px-2 py-1 border border-gray-300 rounded-md text-sm text-gray-700 cursor-pointer file:bg-gray-200 file:border-none file:py-1 file:px-3 file:mr-2 file:rounded-md file:text-sm file:cursor-pointer file:hover:bg-gray-300"
                />

              </div>
              {isUploading && <p className="text-blue-600">Uploading...</p>}
            </div>
          </div> */}
          <div className="w-full mt-6  gap-4 justify-center">
            {banners.map((banner, index) => (
              <div key={index} className="w-[30%] flex flex-col justify-center mb-4">
                <div className="flex justify-between items-center w-full">
                  <label className="text-lg w-[10%]">{`Banner ${index + 1}:`}</label>
                  {/* <input
                    type="file"
                    onChange={(e) => handleBannerChange(e.target.files[0], index)}
                    className="block bg-[#C6C5B9] w-full px-2 py-1 border border-gray-300 rounded-md text-sm text-gray-700 cursor-pointer file:bg-gray-200 file:border-none file:py-1 file:px-3 file:mr-2 file:rounded-md file:text-sm file:cursor-pointer file:hover:bg-gray-300"
                  /> */}
                  <input
                    type="file"
                    onChange={(e) => handleBannerChange(e.target.files[0], index)}
                    className="block bg-[#C6C5B9] w-[60%] px-2 py-1 border border-gray-300 rounded-md text-sm text-gray-700 cursor-pointer file:bg-gray-200 file:border-none file:py-1 file:px-3 file:mr-2 file:rounded-md file:text-sm file:cursor-pointer file:hover:bg-gray-300"
                    required={index === 0} // Makes the first banner (index 0) required
                  />

                </div>
              </div>
            ))}
          </div>

          {isUploading && <p className="text-blue-600 text-center mt-2">Uploading...</p>}
          <div className='w-full flex items-center justify-center'>
            {loading ? (
              <div className="loader"></div>  // Circular loader component
            ) : (<button type="submit" className="submit-button-add-product mb-20 mt-12 p-2 w-[20%]">Add Company</button>)}
          </div>


        </form>}
      {loading && <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid border-opacity-75"></div>
      </div>}
    </div>
  );
};

export default AddCompany;

