import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditCompany from '../components/editComponent/EditCompany';

const AllCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [editingCompany, setEditingCompany] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [editingCompanyId, setEditingCompanyId] = useState(null);

  // Fetch companies from API
  const fetchCompanies = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get('https://emkayindia.in/api/v1/company/all', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCompanies(response.data); // Update the companies list
    } catch (error) {
      console.error('Error fetching companies', error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEditClick = (company) => {
    console.log(company);
    setEditingCompany(company); // Set the company to be edited
  };

  const handleSave = async (updatedCompany) => {
    try {

      // Refresh the company list after successful save
      fetchCompanies();

      // Close the edit form and reset the selected company view
      setEditingCompany(null);
      setSelectedCompany(null);
    } catch (error) {
      console.error('Error saving company', error);
    }
  };

  const handleCancel = () => {
    setEditingCompany(null); // Close edit form without saving
  };

  const handleBackClick = () => {
    setSelectedCompany(null); // Close the details view and show the list again
  };

  const filteredCompanies = companies.filter(
    (company) =>
      company.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      company.id.toString().includes(searchQuery)
  );

  return (
    // <div className=" p-4 w-[100%] mt-10 h-[100vh] overflow-y-scroll" >
    <div className={`  w-[100%] mt-10 h-[100vh] ${editingCompany ? 'overflow-y-scroll' : ''}`}>
      {editingCompany ? (
        // EditCompany Form
        <EditCompany
          companyId={editingCompany.id}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      ) : !selectedCompany ? (
        <div className='ml-2 overflow-auto h-[90vh] p-4 mb-16 w-[102%] pr-8 text-[#848484] font-sans'>
          <h2 className="text-3xl font-bold mb-4">All Companies</h2>

          {/* Search Input */}
          <div className="mb-6 mt-6 w-[60%] flex justify-center align-middle">
            <label className="text-lg font-sans">Search Company Name:</label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search companies..."
              className="w-[70%] px-4 py-2 border rounded-lg shadow-md focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          {/* Companies Table */}
          <div className="overflow-x-auto mb-20">
            <table className=" w-[97%]  table-auto border-collapse bg-white shadow-md rounded-lg">
              <thead className="bg-gray-200">
                <tr className=''>
                  <th className="px-6 py-3 text-left text-m font-medium text-gray-600 uppercase tracking-wider">ID</th>
                  <th className="px-6 py-3 text-left text-m font-medium text-gray-600 uppercase tracking-wider">Company Name</th>
                  <th className="px-6 py-3 text-left text-m font-medium text-gray-600 uppercase tracking-wider">Created At</th>
                  <th className="px-6 py-3 text-left text-m font-medium text-gray-600 uppercase tracking-wider w-[15%]">GST No.</th>
                  <th className="px-6 py-3 text-left text-m font-medium text-gray-600 uppercase tracking-wider w-[10%]">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredCompanies.length > 0 ? (
                  filteredCompanies.map((company) => (
                    <tr
                      key={company.id}
                      className="hover:bg-gray-100 cursor-pointer transition duration-200"
                    >
                      <td
                        className="px-6 py-1 whitespace-nowrap text-m font-medium  border"
                        // onClick={() => handleCompanyClick(company)}
                      >
                        {company.id}
                      </td>
                      <td
                        className="px-6 py-1 whitespace-nowrap text-m border"
                        // onClick={() => handleCompanyClick(company)}
                      >
                        {company.companyName}
                      </td>
                      <td
                        className="px-6 py-1 whitespace-nowrap text-m border"
                        // onClick={() => handleCompanyClick(company)}
                      >
                        {new Date(company.createdAt).toLocaleDateString()}
                      </td>
                      <td
                        className="px-6 py-1 whitespace-nowrap text-m border"
                        // onClick={() => handleCompanyClick(company)}
                      >
                        {company.gstNo}
                      </td>
                      <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(company); // Edit button, don't trigger company click
                          }}
                          className=" bg-[#C6C5B9]  px-4 py-2 rounded-md border"
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center py-4 text-gray-500">No companies found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        selectedCompany && (
          <div className=" w-[102%] bg-gray-100 h-[95vh] p-4 pr-8 overflow-y-scroll text-[#ACACAC]">
            {/* Back Button */}
            <div className="flex  justify-between">
              <h2 className="text-3xl font-bold mb-6 ">Company Details</h2>
              <button
                style={{ backgroundColor: '#343a40' }}
                className="text-white font-semibold h-12 mr-8 px-2 rounded-lg shadow-md hover:shadow-lg transition duration-300 ease-in-out"
                onClick={handleBackClick}
              >
                Back to All Companies
              </button>
            </div>

            {/* Title */}


            {/* Company Details Card */}
            <div className="w-[70%] mx-auto">
              {/* Logo */}
              <div className="flex justify-center mb-6">
                <img
                  src={selectedCompany.logo || 'https://via.placeholder.com/150'}
                  alt="Company Logo"
                  className=" rounded-lg border "
                />
              </div>

              {/* Details */}
              <div className="border border-gray-300 rounded-md overflow-hidden divide-y divide-gray-200">
                <p className="p-4 text-gray-700 flex justify-between">
                  <span className="font-semibold text-gray-900">Company Name:</span>
                  <span>{selectedCompany.companyName}</span>
                </p>
                <p className="p-4 text-gray-700 flex justify-between">
                  <span className="font-semibold text-gray-900">GST No.:</span>
                  <span>{selectedCompany.gstNo}</span>
                </p>
                <p className="p-4 text-gray-700 flex justify-between">
                  <span className="font-semibold text-gray-900">Email:</span>
                  <span>{selectedCompany.email}</span>
                </p>
                <p className="p-4 text-gray-700 flex justify-between">
                  <span className="font-semibold text-gray-900">Phone:</span>
                  <span>{selectedCompany.phone}</span>
                </p>
                <p className="p-4 text-gray-700 flex justify-between">
                  <span className="font-semibold text-gray-900">Address:</span>
                  <span>{selectedCompany.address}</span>
                </p>
                <p className="p-4 text-gray-700 flex justify-between">
                  <span className="font-semibold text-gray-900">Contact Person:</span>
                  <span>{selectedCompany.contactPerson}</span>
                </p>
              </div>

              {/* Terms & Conditions */}
              <div className="mt-6">
                <h3 className="text-xl font-semibold text-gray-800 mb-3">Terms & Conditions</h3>
                {selectedCompany.terms?.termsAndConditions?.length > 0 ? (
                  <ul className="list-disc list-inside text-gray-700 border border-gray-300 rounded-md p-4">
                    {selectedCompany.terms.termsAndConditions.map((term, index) => (
                      <li key={index}>{term}</li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-500 italic">No terms and conditions available.</p>
                )}
              </div>

              {/* Edit Button */}
              <div className="flex justify-center mt-8">
                <button
                  onClick={() => handleEditClick(selectedCompany)}
                  className="bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-blue-700 transition duration-300 ease-in-out mb-24"
                >
                  Edit Company
                </button>
              </div>
            </div>
          </div>

        )
      )}
    </div>
  );
};

export default AllCompanies;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import EditCompany from '../components/editComponent/EditCompany';
// // import './css/AllCompanies.css';

// const AllCompanies = () => {
//   const [companies, setCompanies] = useState([]);
//   const [editingCompanyId, setEditingCompanyId] = useState(null);
//   const [searchQuery, setSearchQuery] = useState('');

//   useEffect(() => {
//     const fetchCompanies = async () => {
//       const token = localStorage.getItem('token');
//       try {
//         const response = await axios.get('https://emkayindia.in/api/v1/company/all', {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         setCompanies(response.data);
//       } catch (error) {
//         console.error('Error fetching companies', error);
//       }
//     };

//     fetchCompanies();
//   }, []);

//   const handleEditClick = (companyId) => {
//     setEditingCompanyId(companyId); // Set the ID of the company to be edited
//   };

//   const handleSearch = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleSave = () => {
//     setEditingCompanyId(null); // Reset after saving changes
//     // Optionally, refresh the companies list here if needed
//   };

//   const handleCancel = () => {
//     setEditingCompanyId(null); // Close the edit form without saving
//   };

//   // Filter companies based on search query
//   const filteredCompanies = companies.filter(
//     (company) =>
//       company.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//       company.id.toString().includes(searchQuery)
//   );

//   return (
//     <div className="all-companies-container p-4 w-[83%] ml-64 mt-14">
//       {editingCompanyId ? (
//         <EditCompany
//           companyId={editingCompanyId}
//           onSave={handleSave}
//           onCancel={handleCancel}
//         />
//       ) : (
//         <div>
//           <h2 className="text-2xl font-bold mb-4">All Companies</h2>

//           <div className="mb-6 mt-6 w-[50%] flex justify-center align-middle">
//             <label className="font-medium font-sans">Search Company:</label>
//             <input
//               type="text"
//               value={searchQuery}
//               onChange={handleSearch}
//               placeholder="Search companies..."
//               className="w-[75%] px-4 py-2 border rounded-lg shadow-md focus:outline-none focus:ring focus:border-blue-300"
//             />
//           </div>

//           <div className="overflow-x-auto">
//             <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg">
//               <thead className="bg-gray-200">
//                 <tr>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">ID</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Company Name</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Created At</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Actions</th>
//                 </tr>
//               </thead>
//               <tbody className="bg-white divide-y divide-gray-200">
//                 {filteredCompanies.length > 0 ? (
//                   filteredCompanies.map((company) => (
//                     <tr key={company.id} className="hover:bg-gray-100 transition duration-200">
//                       <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{company.id}</td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{company.companyName}</td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         {new Date(company.createdAt).toLocaleDateString()}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         <button
//                           onClick={() => handleEditClick(company.id)}
//                           className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
//                         >
//                           Edit
//                         </button>
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="4" className="text-center py-4 text-gray-500">No companies found</td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AllCompanies;

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import EditCompany from '../components/editComponent/EditCompany';

// const AllCompanies = () => {
//   const [companies, setCompanies] = useState([]);
//   const [editingCompanyId, setEditingCompanyId] = useState(null);
//   const [selectedCompany, setSelectedCompany] = useState(null);  // State to store selected company
//   const [searchQuery, setSearchQuery] = useState('');

//   useEffect(() => {
//     const fetchCompanies = async () => {
//       const token = localStorage.getItem('token');
//       try {
//         const response = await axios.get('https://emkayindia.in/api/v1/company/all', {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         setCompanies(response.data);
//       } catch (error) {
//         console.error('Error fetching companies', error);
//       }
//     };

//     fetchCompanies();
//   }, []);

//   const handleEditClick = (companyId) => {
//     setEditingCompanyId(companyId); // Set the ID of the company to be edited
//   };

//   const handleSearch = (event) => {
//     setSearchQuery(event.target.value); // Update search query
//   };

//   const handleSave = () => {
//     setEditingCompanyId(null); // Reset after saving changes
//     // Optionally, refresh the companies list here if needed
//   };

//   const handleCancel = () => {
//     setEditingCompanyId(null); // Close the edit form without saving
//   };

//   const handleCompanyClick = (company) => {
//     setSelectedCompany(company); // Set the selected company to display full details
//   };

//   // Filter companies based on search query (name or ID)
//   const filteredCompanies = companies.filter(
//     (company) =>
//       company.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//       company.id.toString().includes(searchQuery)
//   );

//   return (
//     <div className="all-companies-container p-4 w-[83%] ml-64 mt-14">
//       {selectedCompany ? (
//         // Show selected company details
//         <div className="company-details">
//           <h2 className="text-2xl font-bold mb-4">Company Details</h2>
//           <div className="bg-white p-6 rounded-lg shadow-md">
//             <div className="grid grid-cols-2 gap-4">
//               <div>
//                 <strong className="text-gray-600">Company Name:</strong>
//                 <p className="text-gray-800">{selectedCompany.companyName}</p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Company ID:</strong>
//                 <p className="text-gray-800">{selectedCompany.id}</p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">GST Number:</strong>
//                 <p className="text-gray-800">{selectedCompany.gstNo}</p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Email:</strong>
//                 <p className="text-gray-800">{selectedCompany.email}</p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Phone:</strong>
//                 <p className="text-gray-800">{selectedCompany.phone}</p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Address:</strong>
//                 <p className="text-gray-800">{selectedCompany.address}</p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Created At:</strong>
//                 <p className="text-gray-800">
//                   {new Date(selectedCompany.createdAt).toLocaleDateString()}
//                 </p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Terms and Conditions:</strong>
//                 <ul className="list-disc pl-5 text-gray-800">
//                   {selectedCompany.terms?.termsAndConditions?.map((term, index) => (
//                     <li key={index}>{term}</li>
//                   ))}
//                 </ul>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Logo:</strong>
//                 {selectedCompany.logo && (
//                   <div className="mt-2">
//                     <img
//                       src={selectedCompany.logo}
//                       alt="Company Logo"
//                       className="w-32 h-32 object-cover"
//                     />
//                     <a
//                       href={selectedCompany.logo}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       className="text-blue-500 mt-2 block"
//                     >
//                       View Full Image
//                     </a>
//                   </div>
//                 )}
//               </div>
//               <div>
//                 <strong className="text-gray-600">Banner:</strong>
//                 {selectedCompany.banner && (
//                   <div className="mt-2">
//                     <img
//                       src={selectedCompany.banner}
//                       alt="Company Banner"
//                       className="w-full h-32 object-cover"
//                     />
//                     <a
//                       href={selectedCompany.banner}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       className="text-blue-500 mt-2 block"
//                     >
//                       View Full Image
//                     </a>
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="mt-4">
//               <button
//                 onClick={() => setSelectedCompany(null)} // Go back to list of companies
//                 className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
//               >
//                 Back to Companies
//               </button>
//             </div>
//           </div>
//         </div>
//       ) : editingCompanyId ? (
//         <EditCompany
//           companyId={editingCompanyId}
//           onSave={handleSave}
//           onCancel={handleCancel}
//         />
//       ) : (
//         <div>
//           <h2 className="text-2xl font-bold mb-4">All Companies</h2>

//           <div className="mb-6 mt-6 w-[50%] flex justify-center align-middle">
//             <label className="font-medium font-sans">Search Company:</label>
//             <input
//               type="text"
//               value={searchQuery}
//               onChange={handleSearch}
//               placeholder="Search companies..."
//               className="w-[75%] px-4 py-2 border rounded-lg shadow-md focus:outline-none focus:ring focus:border-blue-300"
//             />
//           </div>

//           <div className="overflow-x-auto">
//             <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg">
//               <thead className="bg-gray-200">
//                 <tr>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">ID</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Company Name</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Created At</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Actions</th>
//                 </tr>
//               </thead>
//               <tbody className="bg-white divide-y divide-gray-200">
//                 {filteredCompanies.length > 0 ? (
//                   filteredCompanies.map((company) => (
//                     <tr
//                       key={company.id}
//                       className="hover:bg-gray-100 transition duration-200 cursor-pointer"
//                       onClick={() => handleCompanyClick(company)} // Click to view details
//                     >
//                       <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{company.id}</td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{company.companyName}</td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         {new Date(company.createdAt).toLocaleDateString()}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         <button
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             handleEditClick(company.id); // Edit button, don't trigger company click
//                           }}
//                           className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
//                         >
//                           Edit
//                         </button>
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="4" className="text-center py-4 text-gray-500">No companies found</td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AllCompanies;



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import EditCompany from '../components/editComponent/EditCompany';

// const AllCompanies = () => {
//   const [companies, setCompanies] = useState([]);
//   const [editingCompanyId, setEditingCompanyId] = useState(null);
//   const [selectedCompany, setSelectedCompany] = useState(null);  // State to store selected company
//   const [searchQuery, setSearchQuery] = useState('');

//   useEffect(() => {
//     const fetchCompanies = async () => {
//       const token = localStorage.getItem('token');
//       try {
//         const response = await axios.get('https://emkayindia.in/api/v1/company/all', {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         setCompanies(response.data);
//       } catch (error) {
//         console.error('Error fetching companies', error);
//       }
//     };

//     fetchCompanies();
//   }, []);

//   const handleEditClick = (companyId) => {
//     setEditingCompanyId(companyId); // Set the ID of the company to be edited
//   };

//   const handleSearch = (event) => {
//     setSearchQuery(event.target.value); // Update search query
//   };

//   const handleSave = () => {
//     setEditingCompanyId(null); // Reset after saving changes
//     // Optionally, refresh the companies list here if needed
//   };

//   const handleCancel = () => {
//     setEditingCompanyId(null); // Close the edit form without saving
//   };

//   const handleCompanyClick = (company) => {
//     setSelectedCompany(company); // Set the selected company to display full details
//   };

//   // Filter companies based on search query (name or ID)
//   const filteredCompanies = companies.filter(
//     (company) =>
//       company.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//       company.id.toString().includes(searchQuery)
//   );

//   return (
//     <div className="all-companies-container p-4 w-[83%] ml-64 mt-14">
//       {selectedCompany ? (
//         // Show selected company details
//         <div className="company-details">
//           <div className="flex justify-between items-center mb-6">
//             <button
//               onClick={() => setSelectedCompany(null)} // Go back to list of companies
//               className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
//             >
//               Back to Companies
//             </button>
//             {/* <button
//               onClick={() => handleEditClick(selectedCompany.id)} // Edit button
//               className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
//             >
//               Edit Company
//             </button> */}
//           </div>
//           <h2 className="text-2xl font-bold mb-4">Company Details</h2>
//           <div className="bg-white p-6 rounded-lg shadow-md">
//             <div className="grid grid-cols-2 gap-4">
//               <div>
//                 <strong className="text-gray-600">Company Name:</strong>
//                 <p className="text-gray-800">{selectedCompany.companyName}</p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Company ID:</strong>
//                 <p className="text-gray-800">{selectedCompany.id}</p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">GST Number:</strong>
//                 <p className="text-gray-800">{selectedCompany.gstNo}</p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Email:</strong>
//                 <p className="text-gray-800">{selectedCompany.email}</p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Phone:</strong>
//                 <p className="text-gray-800">{selectedCompany.phone}</p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Address:</strong>
//                 <p className="text-gray-800">{selectedCompany.address}</p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Created At:</strong>
//                 <p className="text-gray-800">
//                   {new Date(selectedCompany.createdAt).toLocaleDateString()}
//                 </p>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Terms and Conditions:</strong>
//                 <ul className="list-disc pl-5 text-gray-800">
//                   {selectedCompany.terms?.termsAndConditions?.map((term, index) => (
//                     <li key={index}>{term}</li>
//                   ))}
//                 </ul>
//               </div>
//               <div>
//                 <strong className="text-gray-600">Logo:</strong>
//                 {selectedCompany.logo && (
//                   <div className="mt-2">
//                     <img
//                       src={selectedCompany.logo}
//                       alt="Company Logo"
//                       className="w-32 h-32 object-cover"
//                     />
//                     <a
//                       href={selectedCompany.logo}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       className="text-blue-500 mt-2 block"
//                     >
//                       View Full Image
//                     </a>
//                   </div>
//                 )}
//               </div>
//               <div>
//                 <strong className="text-gray-600">Banner:</strong>
//                 {selectedCompany.banner && (
//                   <div className="mt-2">
//                     <img
//                       src={selectedCompany.banner}
//                       alt="Company Banner"
//                       className="w-full h-32 object-cover"
//                     />
//                     <a
//                       href={selectedCompany.banner}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       className="text-blue-500 mt-2 block"
//                     >
//                       View Full Image
//                     </a>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : editingCompanyId ? (
//         <EditCompany
//           companyId={editingCompanyId}
//           onSave={handleSave}
//           onCancel={handleCancel}
//         />
//       ) : (
//         <div>
//           <h2 className="text-2xl font-bold mb-4">All Companies</h2>

//           <div className="mb-6 mt-6 w-[50%] flex justify-center align-middle">
//             <label className="font-medium font-sans">Search Company:</label>
//             <input
//               type="text"
//               value={searchQuery}
//               onChange={handleSearch}
//               placeholder="Search companies..."
//               className="w-[75%] px-4 py-2 border rounded-lg shadow-md focus:outline-none focus:ring focus:border-blue-300"
//             />
//           </div>

//           <div className="overflow-x-auto">
//             <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg">
//               <thead className="bg-gray-200">
//                 <tr>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">ID</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Company Name</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Created At</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Actions</th>
//                 </tr>
//               </thead>
//               <tbody className="bg-white divide-y divide-gray-200">
//                 {filteredCompanies.length > 0 ? (
//                   filteredCompanies.map((company) => (
//                     <tr
//                       key={company.id}
//                       className="hover:bg-gray-100 transition duration-200 cursor-pointer"
//                       onClick={() => handleCompanyClick(company)} // Click to view details
//                     >
//                       <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{company.id}</td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{company.companyName}</td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         {new Date(company.createdAt).toLocaleDateString()}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         <button
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             handleEditClick(company.id); // Edit button, don't trigger company click
//                           }}
//                           className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
//                         >
//                           Edit
//                         </button>
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="4" className="text-center py-4 text-gray-500">No companies found</td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AllCompanies;
