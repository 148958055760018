import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/AddProduct.css';
import { toast } from 'react-toastify';

const AddProduct = ({ toggleComponent }) => {
  const [productData, setProductData] = useState({
    name: '',
    description: '',
    description1: '',
    // price: '',
    gst: '',
    hsn: '',
    category: '',
    // subCategory: '',
    brand: '',
    variant: '',
    variant1: '',
    variant2: '',
    variant3: '',
    variant4: '',
    companyId: [],
    variants: [],
    images: [''], // For dynamic image input fields
  });

  const [companies, setCompanies] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // To handle the loading state
  const [loading, setLoading] = useState(false); // To manage loading state for product creation
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [category, setCategory] = useState(''); // Stores the single category name
  const [categories, setCategories] = useState([]); // To store the fetched categories
  const [selectedCategory, setSelectedCategory] = useState('');
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');


  // Fetch companies
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('https://emkayindia.in/api/v1/company/all', {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => setCompanies(response.data))
      .catch(error => console.error('Error fetching companies:', error));
    fetchCategories();
  }, []);


  const addVariant = () => {
    setProductData((prev) => ({
      ...prev,
      variants: [...prev.variants, { name: '', price: '' }],
    }));
  };

  // Handle dynamic variant input change
  // Handle dynamic variant input change
  const handleVariantChange = (index, field, value) => {
    const updatedVariants = productData.variants.map((variant, i) => {
      if (i === index) {
        return {
          ...variant,
          [field]: field === 'price' ? parseFloat(value) || 0 : value, // Convert price to float
        };
      }
      return variant;
    });
    setProductData({ ...productData, variants: updatedVariants });
  };

  // Remove a variant
  const removeVariant = (index) => {
    const updatedVariants = productData.variants.filter((_, i) => i !== index);
    setProductData({ ...productData, variants: updatedVariants });
  };
  // Handle input changes
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setProductData({ ...productData, [name]: value });
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Trim leading spaces from the value
    const trimmedValue = value.replace(/^\s+/, '');
    setProductData({ ...productData, [name]: trimmedValue });
  };


  // Handle company checkbox selection
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setProductData(prevState => ({
      ...prevState,
      companyId: checked
        ? [...prevState.companyId, value]
        : prevState.companyId.filter(id => id !== value),
    }));
  };

  // Upload image to the provided API
  const uploadImage = async (file) => {
    setIsUploading(true); // Start loading state
    const formData = new FormData();
    formData.append('image', file);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post('https://emkayindia.in/api/v1/image/upload', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      return response.data.url; // Return the uploaded image URL
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image');
      return null;
    } finally {
      setIsUploading(false); // Stop loading state
    }
  };

  // Handle image input changes and upload the image
  const handleImageChange = async (index, file) => {
    const uploadedUrl = await uploadImage(file);
    if (uploadedUrl) {
      // Set the image URL with key format `image${index + 1}`
      setProductData(prevState => ({
        ...prevState,
        [`image${index + 1}`]: uploadedUrl, // Save the uploaded image URL as `image1`, `image2`, etc.
      }));
    }
  };

  // Add image field
  const addImageField = () => {
    setProductData(prevState => ({
      ...prevState,
      images: [...prevState.images, ''], // Add an empty field for another image
    }));
  };

  // Remove image field
  const removeImageField = (index) => {
    setProductData(prevState => ({
      ...prevState,
      images: prevState.images.filter((_, i) => i !== index),
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!productData.companyId.length) {
      toast.error('Please select at least one company.');
      return;
    }
    setLoading(true);
    const updatedVariants = productData.variants.map((variant) => ({
      ...variant,
      price: parseFloat(variant.price) || 0, // Ensure price is converted to a float, defaulting to 0 if invalid
    }));

    // Prepare the final product data
    const productDataToSend = {
      ...productData,
      variants: updatedVariants, // Replace with updated variants
      category: selectedCategory,
      subCategory: selectedSubcategory,
      gst: parseFloat(productData.gst), // Convert GST to float
    };
    console.log(productDataToSend);
    const token = localStorage.getItem('token');
    axios.post('https://emkayindia.in/api/v1/product/create', productDataToSend, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        toast.success('Product added successfully!');
        toggleComponent('AddProduct');
      })
      .catch(error => console.error('Error adding product:', error))
      .finally(() => {
        setLoading(false); // Reset loading to false after the request completes
      });
  };

  // Toggle dropdown state
  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Selected company names
  const selectedCompanyNames = companies
    .filter(company => productData.companyId.includes(company.id))
    .map(company => company.companyName);



  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('https://emkayindia.in/api/v1/category/all', {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }) // Replace with your category API endpoint
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
  }, [selectedCategory]);

  const fetchCategories = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get('https://emkayindia.in/api/v1/category/all', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCategories(response.data); // Set the fetched categories to state
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    const token = localStorage.getItem('token');
    axios.get(`https://emkayindia.in/api/v1/subcategory/all?categoryId=${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }) // Replace with your subcategory API endpoint
      .then(response => {
        console.log(response.data);
        setSubcategories(response.data)
      })
      .catch(error => console.error('Error fetching subcategories:', error));
    console.log('Selected Category:', e.target.value); // This is where you can handle the selected category
  };
  const handleSubcategoryChange = (e) => {
    setSelectedSubcategory(e.target.value);
  };

  // Function to handle dialog open
  const openDialog = () => setIsDialogOpen(true);

  // Function to handle dialog close
  const closeDialog = () => {
    setIsDialogOpen(false);
    setCategory(''); // Reset the input field
  };

  // Function to handle category submission
  const handleAddCategory = () => {
    // console.log('Category Added:', category);
    const token = localStorage.getItem('token');
    const categoryToSend = {
      name: category
    }
    axios.post('https://emkayindia.in/api/v1/category/create', categoryToSend, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        toast.success('Category added successfully!');
        // toggleComponent('AddProduct');
      })
      .catch(error => console.error('Error adding Category:', error))
    // .finally(() => {
    //   setLoading(false); // Reset loading to false after the request completes
    // });
    closeDialog();
    // Add any additional functionality for saving category to the backend or state here
  };

  return (
    <div className="add-product-container">
      {loading ? (
        <div className="loading-skeleton">
          <p>Loading...</p> {/* Replace with any spinner or skeleton component */}
        </div>
      ) : (
        <form className="product-form font-sans text-[#848484] h-[95h] " onSubmit={handleSubmit}>
          <h2 className="text-3xl font-bold mt-4">New Product</h2>

          <div className="form-section flex flex-col justify-start mt-2 w-[90%]">
            <h3 className='mb-4 text-xl'>Product Details</h3>
            <div className="form-row w-[100%] flex justify-between text-lg">
              <div className='w-[30%]'>
                <label className='text-lg'>Name:</label>
                <input type="text" name="name" className='w-[100%]' value={productData.name} onChange={handleInputChange} required />
              </div>
              <div className='w-[30%]'>
                <label className='text-lg'>Brand:</label>
                <input type="text" name="brand" className='w-[100%]' value={productData.brand} onChange={handleInputChange} />
              </div>
              <div className='w-[30%]'>
                <label className='text-lg'>HSN:</label>
                <input type="text" name="hsn" className='w-[100%]' value={productData.hsn} onChange={handleInputChange} required />
              </div>



            </div>
            <div className="form-row flex justify-between w-[100%] ">

              {/* Category:
                <input type="text" name="category" value={productData.category} onChange={handleInputChange} required /> */}
              {/* <div className="w-[60%]">
                  <label>Select Category:</label>
                  <select
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                    className="w-full p-2 border border-gray-300 font-bold bg-white"
                  >
                    <option value="">Select a category</option>
                    {categories.map(category => (
                      <option key={category.id} value={category.id} className="bg-white">
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div> */}
              <div className="w-[30%] mt-[-0.5rem]">
                <label className="text-lg">Category:</label>
                <div className="relative">
                  <select
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                    className="w-full px-3 py-2 border border-gray-300 bg-gray-100 text-lg text-gray-300 appearance-none "
                  >
                    <option value="">Select a category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  <span className="absolute inset-y-0 right-2 flex items-center pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </span>
                </div>
              </div>



              {/* SubCategory: */}
              {/* <input type="text" name="subCategory" value={productData.subCategory} onChange={handleInputChange} /> */}
              {/* <div className="w-[50%]">
                  <label>Select Subcategory:</label>
                  <select
                    onChange={handleSubcategoryChange}
                    value={selectedSubcategory}
                    className="w-full p-2 border border-gray-300   bg-white"
                    disabled={!subcategories.length} // Disable if no subcategories available
                  >
                    <option value="">Select a subcategory</option>
                    {subcategories.map(subcategory => (
                      <option key={subcategory.id} value={subcategory.id} className=" bg-white">
                        {subcategory.name}
                      </option>
                    ))}
                  </select>
                </div> */}
              <div className="w-[30%] mt-[-0.5rem]">
                <label className="text-lg">Subcategory:</label>
                <div className="relative">
                  <select
                    onChange={handleSubcategoryChange}
                    value={selectedSubcategory}
                    className={`w-full px-3 py-2 text-gray-300 border border-gray-300 bg-gray-100 text-lg appearance-none focus:outline-none focus:ring-1 focus:ring-gray-400 ${!subcategories.length ? "cursor-not-allowed bg-gray-200 text-gray-300" : ""
                      }`}
                    disabled={!subcategories.length}
                  >
                    <option value="" >Select a subcategory</option>
                    {subcategories.map((subcategory) => (
                      <option key={subcategory.id} value={subcategory.id}>
                        {subcategory.name}
                      </option>
                    ))}
                  </select>
                  <span className="absolute inset-y-0 right-2 flex items-center pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </span>
                </div>
              </div>

              <div className='flex w-[30%] justify-between'>
                {/* <div className='w-[60%]'>
                  <label className='text-lg'>Price in INR:</label>
                  <input type="number" name="price" className='w-[100%]' value={productData.price} />
                </div> */}
                <div className=''>
                  <label className='text-lg'>GST (%):</label>
                  <input type="number" name="gst" className='w-[100%]' value={productData.gst} onChange={handleInputChange} required />
                </div>
              </div>




            </div>
            <div className="form-row flex flex-col w-[100%]">
              <div className='w-[100%]'>
                <label className='text-lg'>Short Description:</label>
                <textarea name="description" className='w-[100%]' value={productData.description} onChange={handleInputChange} required />
              </div>
              <div className='w-[100%]'>
                <label className='text-lg'>Long Description:</label>
                <textarea name="description1" className='w-[100%]' value={productData.description1} onChange={handleInputChange} />
              </div>




            </div>
          </div>

          {/* Product Variants Section */}
          <div className="form-section mt-4 w-[90%]">
            {/* <h3 className='mt-4 text-xl'>Product Variants</h3> */}
            {/* <div className=" flex flex-col justify-start">
              <div className='flex justify-between mt-4'>
                <div className='w-[30%]'>
                  <label  className='text-lg'>Variant 1:</label>
                  <input type="text" name="variant" value={productData.variant} onChange={handleInputChange} className='w-[100%]' />
                </div>
                <div className='w-[30%]'>
                  <label className='text-lg'>Variant 2:</label>
                  <input type="text" name="variant1" value={productData.variant1} onChange={handleInputChange} className='w-[100%]' />
                </div>
                <div className='w-[30%]'>
                  <label className='text-lg'>Variant 3:</label>
                  <input type="text" name="variant2" value={productData.variant2} onChange={handleInputChange} className='w-[100%]' />
                </div>
              </div>
              <div className='flex justify-between'>
                <div className='w-[30%]'>
                  <label className='text-lg'>Variant 4:</label>
                  <input type="text" name="variant3" value={productData.variant3} onChange={handleInputChange} className='w-[100%]' />
                </div>
                <div className='w-[30%]'>
                  <label className='text-lg'>Variant 5:</label>
                  <input type="text" name="variant4" value={productData.variant4} onChange={handleInputChange} className='w-[100%]' />
                </div>
                <div className='w-[30%]'></div>
              </div>
            </div> */}
            {/* <button type="button" onClick={addVariant}>
              Add Variant
            </button>
            {productData.variants.map((variant, index) => (
              <div key={index}>
                <input
                  type="text"
                  placeholder="Variant Name"
                  value={variant.name}
                  onChange={(e) => handleVariantChange(index, 'name', e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Variant Price"
                  value={variant.price}
                  onChange={(e) => handleVariantChange(index, 'price', e.target.value)}
                />
                <button type="button" onClick={() => removeVariant(index)}>
                  Remove
                </button>
              </div>
            ))} */}
            {/* <div className="form-section mt-4 w-[90%]">
              <h3 className="text-xl font-semibold mb-4">Product Variants</h3>



         
              {productData.variants.map((variant, index) => (
                <div
                  key={index}
                  className="flex items-center mb-4 space-x-4"
                >

                  <span className="text-sm font-medium text-gray-700">
                    Variant {index + 1}:
                  </span>

                  <input
                    type="text"
                    placeholder="Name"
                    value={variant.name}
                    onChange={(e) => handleVariantChange(index, "name", e.target.value)}
                    className="w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none"
                  />
                  <input
                    type="number"
                    placeholder="Price"
                    value={variant.price}
                    onChange={(e) =>
                      handleVariantChange(index, "price", parseFloat(e.target.value) || "")
                    }
                    className="w-1/4 px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none"
                  />

                  <button
                    type="button"
                    onClick={() => removeVariant(index)}
                    className="bg-red-500 text-white px-3 py-2 rounded-md hover:bg-red-600 transition duration-200"
                  >
                    Remove
                  </button>
                </div>
              ))}


              {productData.variants.length < 5 &&
                Array.from({ length: 5 - productData.variants.length }).map((_, i) => (
                  <div
                    key={productData.variants.length + i}
                    className="flex items-center mb-4 space-x-4"
                  >
                    <span className="text-sm font-medium text-gray-700">
                      Variant {productData.variants.length + i + 1}:
                    </span>

                    <input
                      type="text"
                      placeholder="Name"
                      className="w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none"
                    />

                    <input
                      type="number"
                      placeholder="Price"
                      className="w-1/4 px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none"
                    />
                  </div>
                ))}

             
            </div>
            <button
                type="button"
                onClick={addVariant}
                className="bg-[#C6C5B9] text-[#848484] py-2 px-4 rounded-md mb-4 w-[20%]"
              >
                Add Variant
              </button> */}
            <div className="form-section mt-4 w-[100%]">
              <h3 className="text-xl font-semibold mb-4">Product Variants</h3>

              {/* Ensure at least one variant is present */}
              {productData.variants.length === 0 && addVariant()}

              {/* Render Variant Inputs */}
              {productData.variants.map((variant, index) => (
                <div
                  key={index}
                  className="flex items-baseline w-[100%] mb-4 space-x-4"
                >
                  {/* Variant Number */}
                  <span className="text-lg  text-[#848484]">
                    Variant {index + 1}:
                  </span>

                  {/* Variant Name */}
                  <input
                    type="text"
                    placeholder="Name"
                    value={variant.name}
                    onChange={(e) => handleVariantChange(index, "name", e.target.value)}
                    className="w-1/3 px-3 py-2 border border-gray-300 "
                  />

                  {/* Variant Price */}
                  <input
                    type="number"
                    placeholder="Price"
                    value={variant.price}
                    onChange={(e) =>
                      handleVariantChange(index, "price", parseFloat(e.target.value) || "")
                    }
                    className="w-1/6 px-3 py-2 border border-gray-300 "
                  />

                  {/* Remove Button */}
                  {productData.variants.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeVariant(index)}
                      className="bg-[#C6C5B9] text-[#848484] py-2 px-4 rounded-md"
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}

              {/* Add Variant Button */}
              <button
                type="button"
                onClick={addVariant}
                className="bg-[#C6C5B9] text-md text-[#848484] py-2 px-4 rounded-md w-[13%] mt-4 ml-[90%]"
              >
                Add Variant
              </button>
            </div>
          </div>

          {/* Company Selection */}


          {/* Image Section with Dynamic Fields */}
          <div className="form-section mt-4 w-[90%]">
            <h3 className='font-sans text-xl'>Product Images</h3>
            {productData.images.map((image, index) => (
              <div key={index} className="form-row mt-2 w-[37%]">
                <label className='text-lg'>
                  Image {index + 1}:
                  <input
                    type="file"
                    onChange={e => handleImageChange(index, e.target.files[0])}
                    required={index === 0} // Require at least the first image field
                    className='w-[100%]'
                  />
                </label>
                {isUploading && <p>Uploading...</p>}
                {index >= 0 && (
                  <button
                    type="button"
                    style={{ background: ' #C6C5B9 ', color: '#848484', border: 'none', padding: '8px 10px', cursor: 'pointer' }}
                    onClick={() => removeImageField(index)}
                    className='text-md'
                  >
                    Remove
                  </button>
                )}
              </div>

            ))}

            <button type="button" onClick={addImageField} className='img-btn  bg-[#C6C5B9] text-md ml-[90%]'>Add Image</button>

            <div className="form-section mt-4 w-[100%] font-sans ">
              <h3 className='font-sans text-xl'>Companies to Add Product In: </h3>
              <div className="company-selection mb-4 mt-4 flex justify-between">
                <div className={` mt-[-4rem] dropdown ${isDropdownOpen ? 'active' : ''}`}>
                  <button className="dropdown-button bg-[#C6C5B9]  text-lg" onClick={toggleDropdown}>Select Companies</button>
                  {isDropdownOpen && (
                    <div className="dropdown-content">
                      {companies.map(company => (
                        <div key={company.id} className='flex '>
                          <input
                            type="checkbox"
                            value={company.id}
                            checked={productData.companyId.includes(company.id)}
                            onChange={handleCheckboxChange}
                          />
                          <p>{company.companyName}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {/* Display selected companies in boxes */}
                <div className=" w-[30%]  h-36 p-3">
                  <p className='text-lg font-semibold'>Selected Companies: </p>
                  {selectedCompanyNames.map((name, index) => (
                    <div key={index} className="selected-company-box h-8 mr-4 mt-4">{name}</div>
                  ))}
                </div>
              </div>
            </div>
          </div>


          <button type="submit" className='  bg-[#C6C5B9] text-[#848484] text-md font-semibold'>Add Product</button>
        </form>
      )}
    </div>
  );
};

export default AddProduct;
